import cx from 'classnames'

import styles from './CloseButton.module.scss'

import CloseIcon from 'assets//img/icon/close-btn.svg'

const CloseButton = (props: any) => {
  const { className, ...otherProps } = props

  return (
    <button className={cx(styles.close, className)} {...otherProps}>
      {/*<i className="bx bx-x"></i>*/}
      <img src={CloseIcon} alt="close-icon" />
    </button>
  )
}

export default CloseButton
