import { t } from '@lingui/macro'

import cx from 'classnames'
import { useState } from 'react'

import { useParams } from 'react-router-dom'

import styles from './Protected.module.scss'

// import appLogoGrey from 'assets/img/brand/app-logo-grey.svg'
import appLogo from 'assets/img/brand/app-logo.svg'
import landingHero from 'assets/img/landing/landing-hero.png'
import protectedImg from 'assets/img/protected/protected-v1.svg'
import { TextInputWithButton, ProtectedPreview } from 'components/common'

import { fetchMessage } from 'services/decrypt-mail.service'

interface CSSStylesWithZoom extends CSSStyleDeclaration {
  zoom: number
}

const Protected = () => {
  const { id } = useParams<{ id: string }>()
  const [password, setPassword] = useState('')
  const [decryptedMessage, setDecryptedMessage] = useState('')
  const [errorMsg, setErrorMsg] = useState('')

  async function onAccessMail() {
    if (!id) return
    try {
      const data = await fetchMessage(id, password)
      setDecryptedMessage(data)
      // reset possible zooming in on iOS
      ;(window.parent.document.body.style as CSSStylesWithZoom).zoom = 1
      // reset page position caused by text input field focusing on mobiles
      window.scrollTo(0, 0)
    } catch (err) {
      setErrorMsg((err as Error).message)
    }
  }

  return (
    <div className={styles.container}>
      {decryptedMessage ? (
        <>
          <div className={cx(styles.outer, styles.protectedContainer)}>
            <div className={cx(styles.inner, styles.forProtectedContent)}>
              <img src={protectedImg} alt="email" />
              <div className={styles.protectedBody}>
                <p className={styles.reminderText}>{t`protectedDes`}</p>
                <ProtectedPreview html={decryptedMessage} />
              </div>
            </div>
          </div>
          <div className={styles.footer}>
            <span className={styles.caption}>{t`Delivered by`}</span>
            <img src={appLogo} alt="Nomsa logo" />
            <h4>Nomsa</h4>
          </div>
        </>
      ) : (
        <div className={styles.outer}>
          <div className={cx(styles.inner, styles.forPwPrompt)}>
            <div className={styles.verification}>
              <img src={appLogo} className={styles.appLogo} alt="" />
              <img src={landingHero} className={styles.landingHero} alt="" />
              <div>
                <h3 className={styles.title}>{t`You've got mail`}</h3>
                <div className={styles.underlineTitle}></div>
              </div>
              <div className={styles.passwordInput}>
                <TextInputWithButton
                  type="password"
                  placeholder={t`Enter password`}
                  value={password}
                  onChange={setPassword}
                  buttonDisabled={!password}
                  onClick={onAccessMail}
                  buttonLabel={t`Access Mail`}
                  loadingButtonLabel={t`Decrypting your mail`}
                  errorMessage={errorMsg}
                />
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default Protected
