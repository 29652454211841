import { i18n } from '@lingui/core'
import { t } from '@lingui/macro'

import { createRef, useEffect, useContext } from 'react'

import { OutboundLink } from 'react-ga'

import { Navigate } from 'react-router-dom'

import styles from './LoginTemplate-v1.module.scss'

import appLogo from 'assets/img/brand/app-logo.svg'
import dgclogoImg from 'assets/img/landing/dgc-logo.png'
import loginImg from 'assets/img/landing/landing-login.svg'
import loginArt from 'assets/img/landing/login-art.svg'
import mptclogoImg from 'assets/img/landing/mptc-logo.png'
import { InfoBanner } from 'components/common'
import { LINKS } from 'config'
import { AuthContext } from 'contexts/auth.context'

const LoginTemplate = (props: any) => {
  const authContext = useContext(AuthContext)
  const infoBannerRef = createRef<HTMLDivElement>()
  const { children } = props

  useEffect(() => {
    function recalculateBannerPos() {
      const scrollTop = (document.documentElement.scrollTop ||
        document.body.scrollTop) as number
      if (infoBannerRef.current) {
        const infoBannerHeight = infoBannerRef.current?.offsetHeight as number
        if (scrollTop > infoBannerHeight) {
          infoBannerRef.current.style.position = 'fixed'
        } else {
          infoBannerRef.current.style.position = 'relative'
        }
      }
    }
    window.addEventListener('scroll', recalculateBannerPos)
    return () => {
      window.removeEventListener('scroll', recalculateBannerPos)
    }
  })

  if (authContext.isAuthenticated) {
    return <Navigate to="/campaigns" />
  }

  return (
    <>
      <InfoBanner innerRef={infoBannerRef} />
      <div className={styles.loginBG}>
        <img className={styles.loginArt} src={loginArt} alt="Nomsa logo"></img>
        <div className={styles.topContainer}>
          <div className={styles.innerContainer}>
            <div className={styles.textContainer}>
              <img
                width={142}
                className={styles.appLogo}
                src={appLogo}
                alt="Nomsa logo"
              ></img>
              {children}
            </div>
            <div className={styles.landingImg}>
              <img src={loginImg} alt="Landing page graphic"></img>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.bottomContainer}>
        <div className={styles.bottomContent}>
          <div>
            <img width={80} src={mptclogoImg} alt="logo" />
            <img width={80} src={dgclogoImg} alt="logo" />
          </div>
          <div className={styles.linkBar}>
            <OutboundLink
              className={styles.navLink}
              eventLabel={i18n._(LINKS.guideUrl)}
              to={i18n._(LINKS.guideUrl)}
              target="_blank"
            >
              {t`Guides`}
            </OutboundLink>
            <OutboundLink
              className={styles.navLink}
              eventLabel={i18n._(LINKS.contactUsUrl)}
              to={i18n._(LINKS.contactUsUrl)}
              target="_blank"
            >
              {t`contact us`}
            </OutboundLink>
          </div>
        </div>
      </div>
    </>
  )
}

export default LoginTemplate
