import { i18n } from '@lingui/core'
import { t } from '@lingui/macro'

import cx from 'classnames'
import { useState, useContext } from 'react'
import { OutboundLink } from 'react-ga'
import { NavLink, useLocation } from 'react-router-dom'

import styles from './NavBar-v1.module.scss'

import AppLogo from 'assets/img/brand/app-logo.svg'
import enFlagIcon from 'assets/img/flag/english.svg'
import kmFlagIcon from 'assets/img/flag/khmer.svg'
import { TextButton } from 'components/common'
import PrimaryButton from 'components/common/primary-button'
import CreateModal from 'components/dashboard/create/create-modal'
import { LINKS } from 'config'
import { AuthContext } from 'contexts/auth.context'
import { ModalContext } from 'contexts/modal.context'
import { logout } from 'services/auth.service'

const NavBar = ({ handleLocaleChange }: any) => {
  const { setAuthenticated, email } = useContext(AuthContext)
  const modalContext = useContext(ModalContext)
  const [menuOpen, setMenuOpen] = useState(false)
  const location = useLocation()
  const currentLocale = window.localStorage.getItem('language')

  function handleOnclickCreateCampaign() {
    setMenuOpen(!menuOpen)
    handleCreateCampaign()
  }

  async function handleOnclickLogout() {
    setMenuOpen(!menuOpen)
    await handleLogout()
  }
  function handleCreateCampaign() {
    modalContext.setModalContent(<CreateModal></CreateModal>)
  }

  async function handleLogout() {
    try {
      await logout()
      setAuthenticated(false)
    } catch (err) {
      console.error(err)
    }
  }

  function isCreatePath() {
    return /^\/campaigns\/\d+$/.test(location.pathname)
  }

  return (
    <nav className={styles.navBar}>
      <div className={styles.navBrand}>
        <a href="/campaigns" className={styles.appLogo}>
          <img src={AppLogo} alt="Nomsa logo" />
        </a>
        <TextButton
          noUnderline
          className={styles.burgerButton}
          onClick={() => setMenuOpen(!menuOpen)}
        >
          <span
            className={cx(styles.burger, { [styles.isActive]: menuOpen })}
          ></span>
        </TextButton>
      </div>
      <div className={cx(styles.navbarLinks, { [styles.isActive]: menuOpen })}>
        <div className={styles.navLeft}>
          <NavLink
            className={({ isActive }) =>
              cx(styles.link, isActive && !isCreatePath() ? styles.active : '')
            }
            to="/campaigns"
            onClick={() => setMenuOpen(!menuOpen)}
          >
            {t`Campaigns`}
          </NavLink>
          <TextButton
            noUnderline
            className={cx(styles.link, { [styles.active]: isCreatePath() })}
            onClick={handleOnclickCreateCampaign}
          >
            {t`Create`}
          </TextButton>
          <OutboundLink
            className={styles.link}
            eventLabel={`Dashboard navbar / ${i18n._(LINKS.guideUrl)}`}
            to={i18n._(LINKS.guideUrl)}
            target="_blank"
            onClick={() => setMenuOpen(!menuOpen)}
          >
            {t`Guides`}
          </OutboundLink>
          <NavLink
            className={({ isActive }) =>
              cx(styles.link, isActive ? styles.active : '')
            }
            to="/settings"
            onClick={() => setMenuOpen(!menuOpen)}
          >
            {t`Settings`}
          </NavLink>
          <OutboundLink
            className={styles.link}
            eventLabel={i18n._(LINKS.contactUsUrl)}
            to={i18n._(LINKS.contactUsUrl)}
            target="_blank"
            onClick={() => setMenuOpen(!menuOpen)}
          >
            {t`contact us`}
          </OutboundLink>
        </div>
        <div className={styles.navRight}>
          <span
            className={cx(
              styles.active,
              styles.link,
              styles.noClick,
              styles.right,
              styles.email
            )}
          >
            {email}
          </span>
          <div className={styles.actionButton}>
            <PrimaryButton
              className={styles.langButton}
              onClick={() => {
                currentLocale === 'km'
                  ? handleLocaleChange('en')
                  : handleLocaleChange('km')
              }}
            >
              <img
                src={currentLocale === 'km' ? enFlagIcon : kmFlagIcon}
                alt="flagIcon"
              />
            </PrimaryButton>
            <TextButton
              noUnderline
              className={cx(
                styles.active,
                styles.link,
                styles.right,
                styles.iconLink
              )}
              onClick={handleOnclickLogout}
            >
              {t`Logout`}
              <i className={cx(styles.icon, 'bx bxs-log-in')}></i>
            </TextButton>
          </div>
        </div>
      </div>
      <div
        className={cx(styles.overlay, { [styles.isActive]: menuOpen })}
        onClick={() => setMenuOpen(!menuOpen)}
      />
    </nav>
  )
}

export default NavBar
