export const kmContent = `
<body>
<h1>លក្ខខណ្ឌនៃការប្រើប្រាស់</h3>
<ol>
    <li>
        <h4>ទូទៅ</h4>
        <ol>
            <li>
                លក្ខខណ្ឌនៃការប្រើប្រាស់ទាំងនេះគ្រប់គ្រងការចូលប្រើរបស់អ្នក និងការប្រើប្រាស់សេវាកម្មរបស់យើង រួមទាំងកម្មវិធី
                (មិនថាជាកម្មវិធី ឬជាគេហទំព័រ ឬទម្រង់ផ្សេងទៀត)ខ្លឹមសាររបស់វា ការជំរុញជូនដំណឹងនិងសម្ភារៈដែលភ្ជាប់មកជាមួយ
                ផ្សេងទៀតទាំងអស់ ដូចដែលបានកំណត់នៅក្នុងតារាងខាងក្រោម (ជារួម " សេវាកម្ម”) ។
            </li>
            <li>
                សេវាកម្មនេះត្រូវបានផ្តល់ជូនអ្នកដោយទីភ្នាក់ងារបច្ចេកវិទ្យារដ្ឋាភិបាល ("DGC")។ ការិយាល័យរបស់ DGC មានទីតាំងនៅអគារ១៣ មហាវិថីព្រះមុនីវង្ស សង្កាត់ស្រះចក ខណ្ឌដូនពេញ រាជធានីភ្នំពេញ ប្រទេសកម្ពុជា 120210
            </li>
            <li>
                តាមរយៈការចូលប្រើ ឬប្រើប្រាស់ផ្នែកណាមួយនៃសេវាកម្មនេះ អ្នកយល់ព្រមដោយគ្មានលក្ខខណ្ឌ និងទទួលយក 
                ស្របច្បាប់ដោយលក្ខខណ្ឌនៃការប្រើប្រាស់ទាំងនេះ និងការកែប្រែណាមួយពីពេលមួយទៅពេលមួយ។ DGC រក្សាសិទ្ធិក្នុង
                ការផ្លាស់ប្តូរលក្ខខណ្ឌនៃការប្រើប្រាស់ទាំងនេះតាមការសម្រេចចិត្តរបស់ខ្លួន និងនៅពេលណាក៏បាន។ អ្នកគួរតែអាន
                លក្ខខណ្ឌនៃការប្រើប្រាស់ដោយប្រុងប្រយ័ត្នរាល់ពេលដែលអ្នកចូលប្រើ ឬប្រើប្រាស់ផ្នែកណាមួយនៃសេវាកម្មនេះ ព្រោះ
                ការចូលប្រើប្រាស់ ឬការប្រើប្រាស់បែបនេះនឹងបង្កើតបានជាកិច្ចព្រមព្រៀងរបស់អ្នកចំពោះលក្ខខណ្ឌនៃការប្រើប្រាស់ និង
                ការកែប្រែណាមួយចំពោះវា។
            </li>
            <li>
                ប្រសិនបើអ្នកមិនយល់ព្រមនឹងលក្ខខណ្ឌនៃការប្រើប្រាស់ទាំងនេះទេ សូមកុំប្រើប្រាស់សេវាកម្មនេះ ឬផ្នែកណាមួយ
                នៃសេវាកម្មនេះឡើយ។            
            </li>
            <li>
                ប្រសិនបើអ្នកកំពុងចូលប្រើ ឬប្រើប្រាស់សេវាកម្មក្នុងនាមអង្គភាពផ្សេងទៀត(ដូចជានិយោជករបស់អ្នក) អ្នកធានា
                និងតំណាងថាអ្នកមានសិទ្ធិអំណាចចាំបាច់ដើម្បីចងអង្គភាពនេះទៅនឹងលក្ខខណ្ឌនៃការប្រើប្រាស់ទាំងនេះ។            
            </li>
        </ol>
    </li>
    <li>
        <h4>ធម្មជាតិនៃសេវាកម្មនេះ</h4>
        <div>សូមមើលកាលវិភាគសម្រាប់ព័ត៌មានបន្ថែម និងលក្ខខណ្ឌទាក់ទងនឹងសេវាកម្មនេះ។</div>
    </li>
    <li>
        <h4>លក្ខខណ្ឌអាជ្ញាប័ណ្ណ និងការរឹតបន្តឹង</h4>
        <ol>
            <li>
            សេវាកម្មរួមទាំងសម្ភារៈដែលអាចរកបាននៅលើ ឬតាមរយៈសេវាកម្មនេះ គឺជាកម្មសិទ្ធិរបស់ផ្តល់អាជ្ញាប័ណ្ណគ្រប់គ្រង
            ឬគ្រប់គ្រងដោយ DGC ។ សូមមើលឃ្លាទី៤(សម្ភារៈភាគីទីបី)សម្រាប់ព័ត៌មានបន្ថែម។            
            </li>
            <li>
                អនុលោមតាមលក្ខខណ្ឌនៃការប្រើប្រាស់ទាំងនេះ DGC ផ្តល់ឱ្យអ្នកនូវសិទ្ធិមិនផ្តាច់មុខ ដកហូតបាន និងមិនអាចផ្ទេរ 
                បានក្នុងការចូលប្រើប្រាស់ និងប្រើប្រាស់សេវាកម្មសម្រាប់គោលបំណងផ្ទាល់ខ្លួន ឬផ្ទៃក្នុងតែប៉ុណ្ណោះ ហើយសម្រាប់តែការ 
                ប្រើប្រាស់ដែលត្រូវបានអនុញ្ញាតដោយមុខងារនៃសេវាកម្ម និងគោលបំណងតែប៉ុណ្ណោះ។ ដោយ DGC ។ ក្នុងចំណោមរបស់ 
                ផ្សេងទៀត អ្នកមិនត្រូវដាក់ពិន្ទុ ផលិតឡើងវិញ កែប្រែ បញ្ច្រាសវិស្វករ បំបែក សម្រួល បោះពុម្ព ចែកចាយឡើងវិញ ឬផ្តល់ 
                អាជ្ញាប័ណ្ណរងសេវាកម្ម ឬផ្នែកណាមួយនៃសេវាកម្មដោយគ្មានការយល់ព្រមជាលាយលក្ខណ៍អក្សរជាមុនពី DGC ឬម្ចាស់ 
                ភាគីទីបីរៀងៗខ្លួនឡើយ។ អ្នកក៏មិនត្រូវប្រើសេវាកម្មដោយបំពានលើច្បាប់ ឬកិច្ចព្រមព្រៀងដែលអាចអនុវត្តបានដែលអ្នក 
                មានជាមួយភាគីទីបីណាមួយឡើយ។ រាល់សិទ្ធិដែលបង្ហាញ ឬបង្កប់ន័យចំពោះសេវាកម្មដែលមិនត្រូវបានផ្តល់ជាពិសេស 
                នៅទីនេះត្រូវបានបម្រុងទុកយ៉ាងច្បាស់លាស់ចំពោះ DGC ។
            </li>
            <li>
                DGC រក្សាសិទ្ធិក្នុងការ៖
                <ol>
                    <li>
                        ធ្វើបច្ចុប្បន្នភាព ឬកែប្រែសេវាកម្មនេះពីពេលមួយទៅពេលមួយ
                    </li>
                    <li>
                        បដិសេធ ឬដាក់កម្រិតលើការចូលប្រើ ឬប្រើប្រាស់សេវាកម្មដោយបុគ្គលជាក់លាក់ណាមួយ ដោយមិនបញ្ជាក់ពី
                        ហេតុផលណាមួយឡើយ។ និង                    
                    </li>
                    <li>
                        ឈប់បន្ត ឬបញ្ចប់សេវាកម្មនេះនៅពេលណាក៏បាន ដោយមិនមានការជូនដំណឹង ឬការទទួលខុសត្រូវចំពោះអ្នក  
                        ទោះជាយ៉ាងនោះក៏ដោយ សិទ្ធិទាំងអស់ដែលបានផ្តល់ឱ្យអ្នកនៅក្រោមនេះ ក៏នឹងត្រូវបញ្ចប់ភ្លាមៗផងដែរ។ អ្នកនឹងបន្តនៅ 
                        ពេលមានការជូនដំណឹងពី DGC ត្រឡប់មកវិញ ឬបំផ្លាញច្បាប់ចម្លងនៃសេវាកម្ម ឬសម្ភារៈទាំងអស់ដែលអ្នកប្រហែលជា 
                        បានទាញយក។                    
                    </li>
                </ol>
            </li>
            <li>
                អ្នកនឹងមិនជ្រៀតជ្រែក ឬព្យាយាមជ្រៀតជ្រែកក្នុងការងារត្រឹមត្រូវនៃសេវាកម្ម ឬធ្វើអ្វីផ្សេងទៀតដែលដាក់បន្ទុកធំមិន 
                សមហេតុផល ឬមិនសមាមាត្រនៅលើម៉ាស៊ីនមេរបស់ DGC ។            
            </li>
            <li>
                ការចូលប្រើគណនី និងសុវត្ថិភាព
                <ol>
                    <li>
                        អ្នកទទួលខុសត្រូវទាំងស្រុងក្នុងការរក្សាការសម្ងាត់ និងសុវត្ថិភាពនៃលិខិតបញ្ជាក់ការផ្ទៀងផ្ទាត់ណាមួយដែលពាក់ 
                        ព័ន្ធនឹងការប្រើប្រាស់សេវាកម្មរបស់អ្នក រួមទាំងសុវត្ថិភាពនៃឧបករណ៍ណាមួយរបស់អ្នកដែលរក្សាទុកព័ត៌មានសម្ងាត់នៃ 
                        ការផ្ទៀងផ្ទាត់។
                    </li>
                    <li>
                        DGC នឹងមានសិទ្ធិ ប៉ុន្តែមិនមានកាតព្វកិច្ចដើម្បីផ្ទៀងផ្ទាត់អត្តសញ្ញាណរបស់អ្នកប្រើប្រាស់សេវានោះទេ។ ដោយមិន 
                        មានការរើសអើងចំពោះអ្វីដែលបានរៀបរាប់ខាងលើ រដ្ឋាភិបាលមិនស្ថិតនៅក្រោមកាតព្វកិច្ចណាមួយដើម្បីផ្ទៀងផ្ទាត់ថា 
                        ឧបករណ៍កំណត់អត្តសញ្ញាណជីវមាត្រណាមួយដែលបានប្រើជាមួយសេវាកម្ម ឬនៅលើឧបករណ៍របស់អ្នកជារបស់អ្នកទេ។ 
                    </li>
                    <li>
                        DGC ត្រូវតែមានឆន្ទានុសិទ្ធិតែមួយគត់ និងដាច់ខាតក្នុងការធ្វើឱ្យមានសុពលភាពនៃលិខិតបញ្ជាក់ការផ្ទៀងផ្ទាត់ណា 
                        មួយនៅពេលណាមួយ ឬតម្រូវឱ្យអ្នកធ្វើការផ្ទៀងផ្ទាត់ឡើងវិញ ឬធ្វើឱ្យឯកសារបញ្ជាក់ការផ្ទៀងផ្ទាត់របស់អ្នកឡើងវិញនៅ 
                        ពេលណាក៏បាន ដោយមិនចាំបាច់បង្ហាញហេតុផលដូចគ្នានោះទេ។ 
                    </li>
                    <li>
                        DGC នឹងមានសិទ្ធិ ប៉ុន្តែមិនមានកាតព្វកិច្ច ដើម្បីធ្វើសកម្មភាព ឬពឹងផ្អែកលើការណែនាំ ព័ត៌មាន ការបញ្ជូនទិន្នន័យ  
                        ឬការទំនាក់ទំងដែលទទួលបានពីគណនី ឬការប្រើប្រាស់សេវាកម្មទាក់ទងនឹងការផ្ទៀងផ្ទាត់ភាពត្រឹមត្រូវរបស់អ្នក ដូចជា 
                        ការណែនាំបែបនេះ។ ព័ត៌មាន ទិន្នន័យ ឬការប្រាស្រ័យទាក់ទងត្រូវបានចេញដោយអ្នក ថាតើត្រូវបានអនុញ្ញាតដោយអ្នកឬ 
                        ក៏អត់។                    
                    </li>
                    <li>
                        សម្រាប់ការជៀសវាងការសង្ស័យ អ្នកគឺជាអ្នកទទួលខុសត្រូវទាំងស្រុងចំពោះការបាត់បង់នូវធម្មជាតិណាមួយដែល 
                        កើតឡើងពីការកែប្រែដែលគ្មានការអនុញ្ញាត ឬក្រៅផ្លូវការដែលបានធ្វើឡើងចំពោះឧបករណ៍របស់អ្នកដែលអនុញ្ញាត ឬ 
                        បង្កើនការចូលប្រើប្រាស់ដោយឯកសិទ្ធិ ឬដកចេញការរឹតបន្តឹងចំពោះការចូលប្រើបែបនេះ ដែលមិនមានគោលបំណង 
                        ដោយក្រុមហ៊ុនផលិត ឬអ្នកផ្តល់ឧបករណ៍របស់អ្នក ឬប្រព័ន្ធប្រតិបត្តិការនៃឧបករណ៍របស់អ្នក (ឧ. "ការ root" ឬ  
                        "jailbreaking" ទូរសព្ទដៃរបស់អ្នក)។                    
                    </li>
                </ol>
            </li>
        </ol>
    </li>
    <li>
        <h4>សម្ភារៈភាគីទីបី</h4>
        <ol>
            <li>
                សេវាកម្មអាចទាមទារ បើក ឬសម្របសម្រួលការចូលប្រើ ឬប្រើប្រាស់កម្មវិធី ឬសេវាកម្មរបស់ភាគីទីបី (“ភាគីទីបី”)។ នៅក្នុងព្រឹត្តិការណ៍បែបនេះ អាចមានលក្ខខណ្ឌនៃការប្រើប្រាស់កម្មវិធី ឬសេវាកម្មភាគីទីបី (“លក្ខខណ្ឌភាគីទីបី”)។ DGC  
                អាចត្រូវបានទាមទារនៅក្រោម ឬជាលទ្ធផលនៃលក្ខខណ្ឌភាគីទីបី ដើម្បីជូនដំណឹងអ្នកអំពីលក្ខខណ្ឌមួយចំនួនដែលអនុវត្ត 
                ចំពោះអ្នក (ទាំងដោយផ្ទាល់ក្នុងនាមអ្នកប្រើប្រាស់ចុងក្រោយ ឬជាភាគីដែលទង្វើ ឬការខកខានអាចបណ្តាលឱ្យ DGC  
                បំពានលក្ខខណ្ឌភាគីទីបី) នៅពេលអ្នកប្រើសេវាកម្ម។ ឧទាហរណ៍នៃលក្ខខណ្ឌភាគីទីបីអាចជាលក្ខខណ្ឌកម្មវិធីប្រភពបើក 
                ចំហ ឬលក្ខខណ្ឌទម្រង់ស្តង់ដារនៃវេទិកាចែកចាយដែលអ្នកទទួលបានផ្នែកណាមួយនៃសេវាកម្ម (ឧទាហរណ៍លក្ខខណ្ឌ  
                Google Play Store ឬ Apple App Store) ដែលចង DGC ជាអ្នកអភិវឌ្ឍ ឬអ្នកប្រើប្រាស់ វេទិកាចែកចាយ ("លក្ខខណ្ឌ 
                ចែកចាយ")។ ព័ត៌មានអំពីលក្ខខណ្ឌនៃភាគីទីបីត្រូវបានបង្កប់នៅក្នុងសេវាកម្ម ដែលគិតរួចហើយនៅក្នុងលក្ខខណ្ឌនៃការ 
                ប្រើប្រាស់ទាំងនេះ ដែលអាចរកបានជាសាធារណៈ (ឧ. លក្ខខណ្ឌនៃការចែកចាយ) ឬបើមិនដូច្នេះទេបានរាយបញ្ជីនៅក្នុង 
                កាលវិភាគនៅទីនេះ។ សម្រាប់ការជៀសវាងការសង្ស័យ ដរាបណាប្រការ៤នេះទាក់ទងនឹងលក្ខខណ្ឌនៃការចែកចាយ  
                លក្ខខណ្ឌនៃការចែកចាយដែលពាក់ព័ន្ធគឺជាលក្ខខណ្ឌនៃវេទិកាជាក់លាក់ដែលអ្នកទទួលបានច្បាប់ចម្លងនៃកម្មវិធី ឬកម្មវិធី 
                ដែលជាផ្នែកនៃសេវាកម្ម។ ឧទាហរណ៍ ប្រសិនបើអ្នកទទួលបានច្បាប់ចម្លងនោះពី Google Play Store នោះលក្ខខណ្ឌ 
                ដែលពាក់ព័ន្ធគឺជាលក្ខខណ្ឌនៃការចែកចាយរបស់ Google។            
            </li>
            <li>
                វាជាទំនួលខុសត្រូវរបស់អ្នកក្នុងការត្រួតពិនិត្យ និងអានកំណែចុងក្រោយបំផុតនៃលក្ខខណ្ឌភាគីទីបីទាំងនេះ ហើយ 
                អ្នកត្រូវបានគេចាត់ទុកថាមានការជូនដំណឹងដូចគ្នានេះ។ ជាពិសេស អ្នកត្រូវបានគេចាត់ទុកថាមានការជូនដំណឹងអំពី 
                លក្ខខណ្ឌរបស់ភាគីទីបីដែល DGC (ក្រោមលក្ខខណ្ឌភាគីទីបី) តម្រូវឱ្យជូនដំណឹងដល់អ្នក ហើយអ្នកយល់ព្រមដោយគ្មាន 
                លក្ខខណ្ឌក្នុងការចងក្រងកាតព្វកិច្ចទាំងអស់នៅក្នុងលក្ខខណ្ឌភាគីទីបីដែលអាចអនុវត្តបានចំពោះអ្នក។ ជាអ្នកប្រើប្រាស់ 
                ចុងក្រោយ។ សម្រាប់ការជៀសវាងការសង្ស័យ ដែលលក្ខខណ្ឌរបស់ភាគីទីបីត្រូវបានរាយបញ្ជី លក្ខខណ្ឌរបស់ភាគីទីបីនឹង 
                ត្រូវបានចាត់ទុកថារួមបញ្ចូលគោលការណ៍ឯកជនភាពណាមួយ និងគោលការណ៍ប្រើប្រាស់ដែលអាចទទួលយកបានតាម 
                ដែលអនុវត្តចំពោះអ្នក។             
            </li>
            <li>
                ប្រសិនបើលក្ខខណ្ឌរបស់ភាគីទីបីតម្រូវឱ្យអ្នកចូលទៅក្នុងកិច្ចព្រមព្រៀងដោយផ្ទាល់ជាមួយភាគីទីបី នោះអ្នកយល់ 
                ព្រមដោយគ្មានលក្ខខណ្ឌក្នុងការចូលទៅក្នុងកិច្ចព្រមព្រៀងនោះ ហើយនៅក្នុងព្រឹត្តិការណ៍ណាមួយ ដើម្បីឱ្យមានកាតព្វកិច្ច 
                ស្របច្បាប់ដោយលក្ខខណ្ឌរបស់ភាគីទីបី។ ដើម្បីជៀសវាងការសង្ស័យ៖
                <ol>
                    <li>
                        លក្ខខណ្ឌរបស់ភាគីទីបីមួយចំនួន (ជាពិសេសលក្ខខណ្ឌប្រភពបើកចំហ) អនុញ្ញាតឱ្យមានអាជ្ញាប័ណ្ណផ្ទាល់ 
                        សម្រាប់អ្នកពីភាគីទីបី ឬអនុសញ្ញាពី DGC មកអ្នក។ ក្នុងករណីបែបនេះ អាជ្ញាប័ណ្ណរបស់អ្នកគឺជាអាជ្ញាប័ណ្ណផ្ទាល់ពីភាគីទី 
                        បីមកអ្នក។ និង
                    </li>
                    <li>
                        លក្ខខណ្ឌនៃកិច្ចព្រមព្រៀងរបស់អ្នកជាមួយភាគីទីបីនឹងគ្រប់គ្រងការប្រើប្រាស់កម្មវិធី ឬសេវាកម្មភាគីទីបីដែល 
                        ពាក់ព័ន្ធរបស់អ្នក មិនមែនលក្ខខណ្ឌនៃការប្រើប្រាស់ទាំងនេះទេ។      
                    </li>
                </ol>        
            </li>
            <li>
                ប្រសិនបើលក្ខខណ្ឌរបស់ភាគីទីបីច្បាស់លាស់ ឬដោយបញ្ជាក់តម្រូវឱ្យ DGC បញ្ចូលលក្ខខណ្ឌជាក់លាក់នៅក្នុង 
                លក្ខខណ្ឌនៃការប្រើប្រាស់ទាំងនេះ (រួមទាំងលក្ខខណ្ឌដែលកំណត់ស្តង់ដារអប្បបរមា ឬអតិបរមាណាមួយនៅទីនេះ និង/ឬ 
                លក្ខខណ្ឌដែលបានពិពណ៌នានៅក្នុងប្រកា៤.៥ ខាងក្រោម) លក្ខខណ្ឌទាំងនោះត្រូវបានចាត់ទុកថាត្រូវបាន រួមបញ្ចូល 
                ដូច្នេះ ("លក្ខខណ្ឌរួមបញ្ចូល") ។ ឧទាហរណ៍នៃលក្ខខណ្ឌដែលបានរួមបញ្ចូលរួមមានបទប្បញ្ញត្តិដែលតម្រូវឱ្យ DGC ផ្តល់ 
                ឱ្យអ្នកនូវការជូនដំណឹងអំពីសិទ្ធិ និងការទទួលខុសត្រូវជាក់លាក់ ឬតម្រូវឱ្យ DGC ដើម្បីធានាថាអ្នកទទួលស្គាល់បញ្ហាមួយ 
                ចំនួន។ ស្រដៀងគ្នានេះដែរ ប្រសិនបើលក្ខខណ្ឌនៃភាគីទីបីច្បាស់លាស់ ឬដោយបញ្ជាក់តម្រូវឱ្យលក្ខខណ្ឌនៃការប្រើប្រាស់ 
                ទាំងនេះត្រូវបានផ្លាស់ប្តូរ ដែលលក្ខខណ្ឌនៃភាគីទីបីត្រូវបានអនុវត្តតាម នោះភាគីនៅទីនេះយល់ព្រមថាលក្ខខណ្ឌនៃការ 
                ប្រើប្រាស់នឹងត្រូវចាត់ទុកថាត្រូវបានផ្លាស់ប្តូរ ប៉ុន្តែក្នុងកម្រិតចាំបាច់តែប៉ុណ្ណោះ។ សម្រាប់ការអនុលោមតាមច្បាប់។           
            </li>
            <li>
                លក្ខខណ្ឌមួយចំនួនរបស់ភាគីទីបីផ្តល់ឱ្យភាគីទីបី ឬតម្រូវឱ្យ DGC ផ្តល់សិទ្ធិឱ្យភាគីទីបី សិទ្ធិដោយផ្ទាល់នៃការអនុវត្ត 
                លក្ខខណ្ឌនៃការប្រើប្រាស់ទាំងនេះក្នុងនាមជាអ្នកទទួលផលពីភាគីទីបីប្រឆាំងនឹងអ្នក។ លក្ខខណ្ឌរបស់ភាគីទីបីត្រូវបាន 
                ចាត់ទុកថាត្រូវបានដាក់បញ្ចូលទៅក្នុងលក្ខខណ្ឌនៃការប្រើប្រាស់ទាំងនេះជាលក្ខខណ្ឌដែលបានរួមបញ្ចូល ហើយអ្នកយល់ 
                ព្រមក្នុងការផ្តល់ឱ្យភាគីទីបី សិទ្ធិផ្ទាល់នៃការអនុវត្តប្រឆាំងនឹងអ្នក។ 
            </li>
            <li>
                សម្រាប់ការជៀសវាងការសង្ស័យ ដោយមិនមានការរើសអើងចំពោះប្រការ៤.៤ ចំពោះវិសាលភាពនៃភាពមិនស៊ី
                សង្វាក់គ្នារវាងលក្ខខណ្ឌនៃការប្រើប្រាស់ទាំងនេះ និងលក្ខខណ្ឌរបស់ភាគីទីបីនោះ លក្ខខណ្ឌក្រោយៗទៀតនឹងមិនអាច 
                ទទួលបានអ្វីទាំងអស់នៅក្នុងលក្ខខណ្ឌរបស់ភាគីទីបី បង្កើនការទទួលខុសត្រូវរបស់ DGC លើសពីអ្វីដែលមានចែងក្នុង 
                ប្រការ៦.            
            </li>
        </ol>
    </li>
    <li>
        <h4>ការយល់ព្រមរបស់អ្នកក្នុងការចូលប្រើមុខងារនៃឧបករណ៍របស់អ្នក</h4>
        <div>
            ការប្រើប្រាស់សេវាកម្មអាចតម្រូវឱ្យអ្នកអនុញ្ញាតការចូលប្រើដោយសេវាកម្មចំពោះមុខងារជាក់លាក់នៃឧបករណ៍របស់អ្នកដូចជាការជូនដំណឹងរុញការទទួលបាន និង/ឬការចែករំលែកទីតាំងរបស់អ្នក ឬការប្រមូលទិន្នន័យពីអ្នកទាក់ទងនឹងសេវាកម្ម។ សូមមើលឃ្លា Error ផង! រកមិនឃើញប្រភពឯកសារយោងទេ។ (គោលការឯកជន)។ ការប្រើប្រាស់សេវាកម្មរបស់អ្នកនឹងបង្កើតការយល់ព្រមរបស់អ្នកចំពោះការចូលប្រើដោយសេវាកម្មនៃមុខងារបែបនេះនៃឧបករណ៍របស់អ្នកដូចដែលអាចត្រូវបានទាមទារដោយសេវាកម្ម។
        </div>
        <ol>
            <li>
                <h4>ភាពជាម្ចាស់នៃមតិកែលម្អ/សំណើ/ការណែនាំ</h4>
                <div>
                    អ្នកយល់ព្រមថាចំណងជើង និងការចាប់អារម្មណ៍ទាំងអស់នៅក្នុងមតិកែលម្អ សំណើ ឬការផ្ដល់យោបល់ណាមួយពីអ្នក
                    ទាក់ទងនឹងសេវាកម្ម នឹងត្រូវគ្រប់គ្រងដោយ DGC ។
                </div>
            </li>
            <li>
                <h4>ការសម្ងាត់</h4>
                <ol>
                    <li>
                        ប្រសិនបើអ្នកទទួលបានព័ត៌មាន ឬទិន្នន័យ (ក្នុងទម្រង់បែបណាក៏ដោយ) ពី DGC ឬភាគីទីបី ដែលត្រូវបានកំណត់ 
                        ថាជាសម្ងាត់ ឬជាកម្មសិទ្ធិ ឬត្រូវបានយល់ដោយហេតុផលថាជាសម្ងាត់ ឬជាកម្មសិទ្ធិ(ជាសមូហភាព "ព័ត៌មានសម្ងាត់") 
                        អ្នកមិនត្រូវប្រើ បង្ហាញឬផលិតឡើងវិញនូវព័ត៌មានសម្ងាត់ លើកលែងតែសម្រាប់គោលបំណងដែលវាត្រូវបានផ្តល់ឱ្យអ្នក។ 
                        ប្រសិនបើការយល់ព្រមក្នុងការបង្ហាញព័ត៌មានសម្ងាត់ដល់ភាគីទីបីត្រូវបានផ្តល់ឱ្យដោយ DGC ឬភាគីទីបីដល់អ្នក ទង្វើ  
                        ឬការលុបចោលណាមួយទាក់ទងនឹងព័ត៌មានសម្ងាត់ដោយបុគ្គលនោះនឹងត្រូវចាត់ទុកថាជាទង្វើ ឬការខកខានរបស់អ្នក  
                        ហើយអ្នកយល់ព្រមទាំងស្រុង។ ទទួលខុសត្រូវដូចគ្នា។ ក្នុងគ្រប់ករណីទាំងអស់ អ្នកត្រូវតែការពារព័ត៌មានសម្ងាត់ក្នុង 
                        កម្រិតដូចគ្នាដែលអ្នកការពារព័ត៌មានសម្ងាត់ផ្ទាល់ខ្លួនរបស់អ្នក ប៉ុន្តែក្នុងករណីមិនតិចជាងស្តង់ដារនៃការថែទាំសមរម្យ 
                        នោះទេ។ អ្នកត្រូវតែធានាថាអ្នកទទួលណាមួយត្រូវបានចងភ្ជាប់ដោយលក្ខខណ្ឌនៃការសម្ងាត់យ៉ាងហោចណាស់ជាការរឹត 
                        បន្តឹងដូចប្រការនេះ។                    
                    </li>
                    <li>
                        អ្នកត្រូវបំផ្លាញព័ត៌មានសម្ងាត់ណាមួយភ្លាមៗតាមការស្នើសុំរបស់ DGC ឬភាគីទីបី។
                    </li>
                    <li>
                        នៅក្នុងព្រឹត្តិការណ៍៖
                        <ol>
                            <li>
                                អ្នកគឺជាអ្នក ឬទំនងជាត្រូវបានទាមទារដោយបញ្ជារបស់តុលាការដើម្បីបង្ហាញព័ត៌មានសម្ងាត់។ ឬ
                            </li>
                            <li>
                                អ្នកមានហេតុផលសមហេតុផលក្នុងការសង្ស័យថាមានការប្រើប្រាស់ដោយគ្មានការអនុញ្ញាត ឬការបង្ហាញ ឬការផលិតឡើងវិញនូវព័ត៌មានសម្ងាត់។ 
                                អ្នកត្រូវជូនដំណឹងជាបន្ទាន់ដល់ DGC ឬភាគីទីបីអំពីដូចគ្នា ហើយសហការជាមួយ DGC ឬភាគីទីបី ដើម្បីទប់ស្កាត់ 
                                ឬកំណត់ការបង្ហាញព័ត៌មានបែបនេះ។ 
                            </li>
                            <li>
                            គ្មានអ្វីនៅក្នុងប្រការ 5.2 នេះ នឹងមិនប៉ះពាល់ដល់សិទ្ធិផ្សេងទៀតរបស់ DGC ឬភាគីទីបីនៅក្នុងច្បាប់នោះទេ។
                            </li>
                        </ol>
                    </li>
                </ol>
            </li>
        </ol>
    </li>
    <li>
        <h4>ការបដិសេធ និងសំណង</h4>
        <ol>
            <li>
                សេវាកម្មត្រូវបានផ្តល់ជូននៅលើមូលដ្ឋាន "ដូចដែលមាន" និង "តាមដែលមាន" ដោយគ្មានការធានាគ្រប់ប្រភេទ។  
                ក្នុងវិសាលភាពពេញលេញបំផុតដែលច្បាប់អនុញ្ញាតនោះ DGC មិនធ្វើតំណាង ឬការធានាប្រភេទណាមួយដែលទាក់ទង 
                នឹងសេវាកម្មនេះឡើយ ហើយដោយហេតុនេះបដិសេធរាល់ការធានាដោយបញ្ជាក់ បង្កប់ន័យ និង/ឬការធានាផ្នែកច្បាប់នៃ 
                ប្រភេទណាមួយចំពោះអ្នក ឬភាគីទីបីណាមួយ ទោះជាកើតឡើងពី ការប្រើប្រាស់ ឬទំនៀមទម្លាប់ ឬពាណិជ្ជកម្ម ឬដោយ 
                ប្រតិបត្តិការនៃច្បាប់ ឬបើមិនដូច្នេះទេ រួមទាំងប៉ុន្តែមិនកំណត់ចំពោះតំណាង ឬការធានាណាមួយឡើយ៖ 
                <ol>
                    <li>
                        អំពីភាពត្រឹមត្រូវ ភាពពេញលេញ ភាពត្រឹមត្រូវ រូបិយប័ណ្ណ ភាពទាន់ពេលវេលា ភាពជឿជាក់ ភាពអាចរកបាន  
                        អន្តរប្រតិបត្តិការ សុវត្ថិភាព ការមិនរំលោភបំពាន ចំណងជើង ភាពអាចជួញដូរបាន គុណភាព ឬសម្បទាសម្រាប់គោល 
                        បំណងជាក់លាក់ណាមួយនៃសេវាកម្ម។ និង/ឬ                
                    </li>
                    <li>
                        ថាសេវាកម្ម ឬមុខងារណាមួយដែលភ្ជាប់ជាមួយនោះនឹងមិនមានការរំខាន ឬគ្មានកំហុស ឬបញ្ហានោះនឹងត្រូវបាន 
                        កែដំរូវ ឬថាសេវាកម្មនេះ គេហទំព័រ និងម៉ាស៊ីនមេ ហើយនឹងមិនមានមេរោគទាំងអស់ និង/ឬកូដព្យាបាទ បំផ្លិចបំផ្លាញ ឬ 
                        ខូច។ កម្មវិធីឬម៉ាក្រូ។               
                    </li>
                </ol>
            </li>
            <li>
                DGC ក៏មិនទទួលខុសត្រូវចំពោះអ្នក ឬតតិយជនណាមួយចំពោះការខូចខាត ឬការបាត់បង់ណាមួយក៏ដោយ ដែល 
                បណ្តាលមកពីមូលហេតុ រួមទាំង ប៉ុន្តែមិនកំណត់ចំពោះការខូចខាតដោយផ្ទាល់ ឬដោយប្រយោល ពិសេស ឬជាលទ្ធផល  
                ការបាត់បង់ប្រាក់ចំណូល ប្រាក់ចំណូល ឬប្រាក់ចំណេញ ការបាត់បង់ ឬ ទិន្នន័យខូច ឬខូចខាតដល់កុំព្យូទ័រ កម្មវិធី ឬទ្រព្យ 
                សម្បត្តិផ្សេងទៀតរបស់អ្នក ទោះជាកើតឡើងដោយផ្ទាល់ ឬដោយប្រយោលពី -
                <ol>
                    <li>
                        ការចូលទៅកាន់ ឬប្រើប្រាស់សេវាកម្មនេះ ឬផ្នែកណាមួយរបស់អ្នក
                    </li>
                    <li>
                        ការបាត់បង់ការចូលប្រើប្រាស់ ឬការប្រើប្រាស់សេវាកម្មនេះ ឬផ្នែកណាមួយនៃសេវាកម្មនេះ ទោះជាបណ្តាលមកពីអ្វីក៏ដោយ                 
                    </li>
                    <li>
                        ភាពមិនត្រឹមត្រូវ ឬភាពមិនពេញលេញនៅក្នុង ឬកំហុស ឬការខកខានក្នុងការបញ្ជូនសេវាកម្ម។ 
                    </li>
                    <li>
                        ការពន្យារពេល ឬការរំខានណាមួយក្នុងការបញ្ជូនសេវា ទោះជាបណ្តាលមកពីការពន្យារពេល ឬការរំខានក្នុងការ បញ្ជូនតាមអ៊ីនធឺណិត ឬបើមិនដូច្នេះទេ ឬ 
                    </li>
                    <li>
                        ការសម្រេចចិត្តណាមួយដែលបានធ្វើឡើង ឬសកម្មភាពដែលធ្វើឡើងដោយអ្នក ឬភាគីទីបីណាមួយ ដោយពឹងផ្អែកលើសេវាកម្ម 
                        ដោយមិនគិតពីថាតើ DGC ត្រូវបានណែនាំអំពីលទ្ធភាពនៃការខូចខាត ឬការបាត់បង់នោះទេ។                
                    </li>
                </ol>          
            </li>
            <li>
                ដោយគ្មានការរើសអើង និងបន្ថែមពីលើអ្វីដែលបានរៀបរាប់ខាងលើ ដរាបណាសេវាកម្មសម្របសម្រួល ឬទាមទារ 
                ការផ្តល់ ការប្រើប្រាស់ ឬដំណើរការ ឬត្រូវបានផ្តល់ដោយភ្ជាប់ជាមួយផលិតផល កម្មវិធី សម្ភារៈ និង/ឬសេវាកម្មផ្សេងទៀត 
                ដែលមិនត្រូវបានផ្តល់ដោយ DGC នោះ DGC មិនតំណាងឱ្យ ឬការធានាទាក់ទងនឹងផលិតផល សូហ្វវែរ សម្ភារៈ និង/ឬសេវាកម្មទាំងនោះ (រាប់បញ្ចូលទាំងការតំណាង ឬការធានាដោយគ្មានដែនកំណត់ចំពោះភាពទាន់ពេលវេលា ភាពជឿជាក់  
                ភាពអាចរកបាន អន្តរប្រតិបត្តិការ គុណភាព សម្បទាសម្រាប់គោលបំណង មិនបំពាន ភាពសមស្រប ឬភាពត្រឹមត្រូវ)។ 
            </li>
            <li>
                អ្នកមិនត្រូវពឹងផ្អែកលើផ្នែកណាមួយនៃសេវាកម្មដើម្បីទាមទារ ឬអះអាងទម្រង់នៃការរំពឹងទុកស្របច្បាប់ណាមួយ 
                ប្រឆាំងនឹង DGC ទោះបីជាកើតឡើង ឬមិនទាក់ទងនឹងតួនាទី និងមុខងាររបស់ DGC ជាអាជ្ញាធរសាធារណៈក៏ដោយ។ 
            </li>
            <li>
                អ្នកយល់ព្រមការពារ និងផ្តល់សំណង និងរក្សា DGC និងមន្ត្រី និយោជិត ភ្នាក់ងារ និងអ្នកម៉ៅការរបស់ខ្លួន ដោយមិន 
                បង្កគ្រោះថ្នាក់ដល់ការទទួលខុសត្រូវ ការបាត់បង់ ការខូចខាត ការចំណាយ ឬការចំណាយ (រួមទាំងថ្លៃដើមផ្លូវច្បាប់លើ 
                មូលដ្ឋានសំណង) ទោះជាកើតឡើងពី ឬពាក់ព័ន្ធនឹងការចូលប្រើរបស់អ្នក ឬ ការប្រើប្រាស់សេវាកម្ម (រួមទាំងកម្មវិធី ឬសេ 
                វាកម្មភាគីទីបី) ឬការមិនអនុលោមតាមលក្ខខណ្ឌនៃការប្រើប្រាស់ លក្ខខណ្ឌនៃភាគីទីបី ឬលក្ខខណ្ឌដែលបានរួមបញ្ចូល  
                ថាតើអ្នកត្រូវបានណែនាំ ឬមិនបានជូនដំណឹងអំពីលក្ខណៈ ឬទំហំនៃបំណុល ការខាតបង់។ ការខូចខាត ការចំណាយ ឬ 
                ការចំណាយ។ អ្នកធានា និងតំណាងឱ្យការចូលប្រើ ឬការប្រើប្រាស់សេវាកម្មរបស់អ្នកមិន ហើយនឹងមិនបំពាន ឬបំពាន 
                ច្បាប់ បទប្បញ្ញត្តិ ពាណិជ្ជកម្ម សេដ្ឋកិច្ច និង/ឬទណ្ឌកម្មនាំចេញ (គ្រប់ទីកន្លែងក្នុងពិភពលោក) ដែលអនុវត្តចំពោះអ្នក ហើយថាអ្នកនឹងមិនបញ្ជូនណាមួយឡើយ។ កូដព្យាបាទ ខុសច្បាប់ ការរំលោភបំពាន ឬខ្លឹមសារដែលមិនចង់បាន ឬសម្ភារៈចំពោះ DGC ឬភ្នាក់ងាររបស់ខ្លួន ឬភាគីទីបីណាមួយ។ 
            </li>
        </ol>
    </li>
    <li>
        <h4>តំណភ្ជាប់ហាយភើលីង(Hyperlink)</h4>
        <ol>
            <li>
                ដរាបណាសេវាកម្មផ្តល់តំណខ្ពស់ទៅកាន់សម្ភារៈដែលមិនត្រូវបានថែរក្សា ឬគ្រប់គ្រងដោយ DGC នោះ DGC នឹង 
                មិនទទួលខុសត្រូវចំពោះខ្លឹមសារនៃសម្ភារៈដែលភ្ជាប់ខ្ពស់នោះទេ ហើយនឹងមិនទទួលខុសត្រូវចំពោះការខូចខាត ឬការ 
                បាត់បង់ណាមួយដែលកើតឡើងពីការចូលប្រើសម្ភារៈតំណខ្ពស់នោះទេ។ ការប្រើប្រាស់តំណខ្ពស់ និងការចូលប្រើសម្ភារៈ 
                តំណខ្ពស់បែបនេះ គឺជាហានិភ័យផ្ទាល់ខ្លួនរបស់អ្នកទាំងស្រុង។ តំណខ្ពស់ត្រូវបានផ្តល់ជូនគ្រាន់តែជាភាពងាយស្រួល 
                សម្រាប់អ្នក និងមិនបញ្ជាក់ពីការយល់ព្រមដោយ សមាគម ឬការពាក់ព័ន្ធជាមួយ DGC នៃមាតិកា ឬអ្នកផ្តល់សម្ភារៈតំណ 
                ខ្ពស់នោះទេ។  
            </li>
            <li>
                ការដាក់ឃ្លាំងសម្ងាត់ និងការភ្ជាប់តំណខ្ពស់ទៅ និងការដាក់ផ្នែកណាមួយនៃសេវាកម្មត្រូវបានហាមឃាត់ រក្សាទុក 
                កន្លែងដែលអ្នកទទួលបានការយល់ព្រមជាលាយលក្ខណ៍អក្សរជាមុនពី DGC ។ ការយល់ព្រមបែបនេះអាចស្ថិតក្រោម 
                លក្ខខណ្ឌណាមួយដែលអាចត្រូវបានកំណត់ដោយ DGC ក្នុងការសម្រេចចិត្តតែមួយគត់របស់ខ្លួន។ ប្រសិនបើអ្នកភ្ជាប់តំណ 
                ខ្ពស់ទៅ ឬដាក់ផ្នែកណាមួយនៃសេវាកម្មនោះ នោះនឹងក្លាយជាការទទួលយករបស់អ្នកនូវលក្ខខណ្ឌនៃការប្រើប្រាស់ទាំង 
                នេះ និងការកែប្រែទាំងអស់នៅទីនោះ។ ប្រសិនបើអ្នកមិនទទួលយកលក្ខខណ្ឌនៃការប្រើប្រាស់ទាំងនេះ ដូចដែលអាចត្រូវ 
                បានកែប្រែពីមួយពេលទៅមួយ អ្នកត្រូវតែបញ្ឈប់ការភ្ជាប់ទៅ ឬបង្កើតផ្នែកណាមួយនៃសេវាកម្មភ្លាមៗ។            
            </li>
            <li>
                <strong>DGC រក្សាសិទ្ធិទាំងអស់៖</strong>
                <ol>
                    <li>
                        ដើម្បីបិទតំណភ្ជាប់ណាមួយ ឬស៊ុមនៃសម្ភារៈទាំងឡាយណាដែលមិនមានការអនុញ្ញាត(រួមទាំងសម្ភារៈដោយ 
                        គ្មានដែនកំណត់ដែលបញ្ជាក់ពីការយល់ព្រមដោយ ឬសមាគម ឬការពាក់ព័ន្ធជាមួយ DGC សម្ភារៈដែលមានប្រធានបទមិន 
                        សមរម្យ អាសអាភាស បង្ខូចកេរ្តិ៍ឈ្មោះ រំលោភបំពាន អាសអាភាស អាសអាភាស ឬមិនស្របច្បាប់ ប្រធានបទ ឈ្មោះ។ ឬ 
                        ព័ត៌មានដែលបំពានច្បាប់ជាលាយលក្ខណ៍អក្សរ កម្មសិទ្ធិបញ្ញាដែលអាចអនុវត្តបាន កម្មសិទ្ធិ ឯកជនភាព ឬសិទ្ធិផ្សព្វផ្សាយ 
                        សាធារណៈ); និង                      
                    </li>
                    <li>
                        ដើម្បីបដិសេធទំនួលខុសត្រូវ និង/ឬ ទំនួលខុសត្រូវចំពោះសម្ភារៈដែលភ្ជាប់ទៅ ឬដាក់ផ្នែកណាមួយនៃសេវាកម្ម។
                    </li>
                </ol>
            </li>
        </ol>
    </li>
    <li>
        <h4>គោលការណ៍ឯកជនភាព</h4>
        <div>
            អ្នកក៏យល់ព្រមនឹងលក្ខខណ្ឌនៃគោលការណ៍ឯកជនភាពសម្រាប់សេវាកម្មនេះផងដែរ ដូចដែលអាចត្រូវបានកែប្រែពីពេល 
            មួយទៅពេលមួយ។ គោលការណ៍ឯកជនភាពនឹងក្លាយជាផ្នែកមួយនៃលក្ខខណ្ឌប្រើប្រាស់ទាំងនេះ។ 
        </div>
    </li>
    <li>
        <h4>សិទ្ធិរបស់ភាគីទីបី</h4>
        <div>
            យោងតាមសិទ្ធិរបស់តតិយជន បុគ្គលដែលមិនមែនជាភាគីនៃលក្ខខណ្ឌនៃការប្រើប្រាស់នេះ នឹងមិនមានសិទ្ធិនៅក្រោមកិច្ច 
            សន្យា(សិទ្ធិរបស់ភាគីទីបី) ច្បាប់ ឬបើមិនដូច្នេះទេដើម្បីអនុវត្តលក្ខខណ្ឌណាមួយរបស់ខ្លួន។ 
        </div>
    </li>
    <li>
        <h4>កិច្ចការ</h4> 
        <ol>
            <li>
                អ្នកមិនអាចប្រគល់ ឬចុះកិច្ចសន្យាបន្តលក្ខខណ្ឌនៃការប្រើប្រាស់នេះដោយគ្មានការយល់ព្រមជាលាយលក្ខណ៍ 
                អក្សរជាមុនពី DGC ឡើយ។            
            </li>
            <li>
                DGC អាចចាត់តាំង បង្កើតថ្មី ផ្ទេរ ឬកិច្ចសន្យាបន្តនូវសិទ្ធិ និងការទទួលខុសត្រូវទាក់ទងនឹងសេវាកម្ម និងលក្ខខណ្ឌ 
                ប្រើប្រាស់នេះ ដោយមិនចាំបាច់ជូនដំណឹងដល់អ្នក និងដោយគ្មានឯកសារយោងបន្ថែមចំពោះអ្នក។ ការយល់ព្រមរបស់អ្នក 
                ចំពោះលក្ខខណ្ឌនៃការប្រើប្រាស់នេះក៏ជាការយល់ព្រមរបស់អ្នកចំពោះកិច្ចការ ការបង្កើតថ្មី ការផ្ទេរឬកិច្ចសន្យាបន្តផងដែរ។            
            </li>
            <li>
                ភាពអាចបំបែកបាន
            <div>
                ប្រសិនបើលក្ខខណ្ឌណាមួយនៃលក្ខខណ្ឌប្រើប្រាស់ទាំងនេះត្រូវបានគ្រប់គ្រងដោយតុលាការ ឬសាលាក្តីនៃយុត្តាធិការមាន 
                សមត្ថកិច្ចថាមិនត្រឹមត្រូវ ឬមិនអាចអនុវត្តបាន នោះលក្ខខណ្ឌនៃការប្រើប្រាស់ទាំងនេះ រួមទាំងលក្ខខណ្ឌដែលនៅសល់ 
                ទាំងអស់នឹងនៅតែមានជាធរមាន និងមានប្រសិទ្ធិភាពដូចជាប្រសិនបើមិនត្រឹមត្រូវ ឬមិនអាចអនុវត្តបាន។ ពាក្យមិនដែល 
                ត្រូវបានរាប់បញ្ចូលទេ ប៉ុន្តែក្នុងកម្រិតដែលអាចអនុញ្ញាតបាន លក្ខខណ្ឌមិនត្រឹមត្រូវ ឬមិនអាចអនុវត្តបាន នឹងត្រូវចាត់ទុក 
                ថាត្រូវបានជំនួសដោយពាក្យដែលមាន (ក) មានសុពលភាព និងអាចអនុវត្តបាន និង (ខ) បង្ហាញពីចេតនា ឬលទ្ធផល 
                ដែលនៅជិតបំផុតទៅនឹងបំណងដើម។ នៃលក្ខខណ្ឌមិនត្រឹមត្រូវ ឬមិនអាចអនុវត្តបាន។ 
            </div>
            </li>
        </ol>
    </li>
    <li>
        <h4>ច្បាប់គ្រប់គ្រង និងដំណោះស្រាយវិវាទ</h4>
        <ol>
            <li>
                លក្ខខណ្ឌនៃការប្រើប្រាស់ទាំងនេះនឹងត្រូវគ្រប់គ្រងដោយ និងបកស្រាយស្របតាមច្បាប់របស់ប្រទេសកម្ពុជា។ 
            </li>
            <li>
                យោងតាមប្រការ១១.៣ វិវាទណាមួយដែលកើតចេញពី ឬពាក់ព័ន្ធនឹងលក្ខខណ្ឌនៃការប្រើប្រាស់ទាំងនេះ រួមទាំង 
                សំណួរទាក់ទងនឹងអត្ថិភាព សុពលភាព ឬការបញ្ចប់របស់វា នឹងត្រូវបញ្ជូនទៅ និងដោះស្រាយជាចុងក្រោយនៅក្នុង 
                តុលាការកម្ពុជា ហើយភាគីនានាដាក់ស្នើនៅទី             
            </li>
            <li>
                DGC អាចយោងតាមការសម្រេចចិត្តតែមួយគត់របស់ខ្លួន យោងលើវិវាទដែលមានចែងក្នុងប្រការ១១.២ ខាងលើ 
                ទៅមជ្ឈត្តកម្មដែលគ្រប់គ្រងដោយគណៈកម្មាធិការរដ្ឋាភិបាលឌីជីថល (“DGC”) នៅក្នុងប្រទេសកម្ពុជាដោយអនុលោម 
                តាមច្បាប់មជ្ឈត្តកម្មរបស់ CIAC ("ច្បាប់ CIAC")សម្រាប់ ពេលវេលាកំពុងចូលជាធរមាន ច្បាប់ណាមួយត្រូវបានចាត់ទុក 
                ថាត្រូវបានបញ្ចូលដោយយោងនៅក្នុងឃ្លានេះ។ បន្ថែមទៀត៖ 
                <ol>
                    <li>
                        អាសនៈនៃមជ្ឈត្តកម្មត្រូវជាប្រទេសកម្ពុជា។
                    </li>
                    <li>
                        សាលាក្តីត្រូវមានអាជ្ញាកណ្តាលម្នាក់(១)
                    </li>
                    <li>
                        ភាសានៃអាជ្ញាកណ្តាលត្រូវជាភាសាអង់គ្លេស
                    </li>
                    <li>
                        រាល់ព័ត៌មាន ការប្តឹងផ្តល់ ឯកសារ ភស្តុតាង និងបញ្ហាទាំងអស់ដែលទាក់ទងនឹងមជ្ឈត្តកម្ម ត្រូវរក្សាការសម្ងាត់។ 
                        នៅពេលដែល DGC គឺជាចុងចោទ ឬអ្នកឆ្លើយតប វាត្រូវបានផ្តល់ឱ្យយ៉ាងហោចណាស់ ៣០ថ្ងៃមុនពេលចាប់ផ្តើមនៃ 
                        សកម្មភាពផ្លូវច្បាប់ណាមួយប្រឆាំងនឹងខ្លួនដើម្បីជ្រើសរើសដើម្បីអនុវត្តសិទ្ធិនៅទីនេះដើម្បីមានវិវាទបញ្ជូនទៅមជ្ឈត្តកម្ម។ 
                        សិទ្ធិក្នុងការបោះឆ្នោតនេះនឹងមិនធ្វើឱ្យប៉ះពាល់ដល់សិទ្ធិរបស់ DGC ក្នុងការការពារកម្រិតកំណត់ទេ ហើយរយៈពេលនៃ 
                        ការអនុវត្តសិទ្ធិនេះ មិនត្រូវបានសង្ខេបដោយហេតុផលនៃការកើនឡើងនៃការការពារកម្រិតណាមួយដែលគាំទ្រដល់ DGC 
                        ក្នុងអំឡុងពេលនោះទេ។ 
                        <div>លក្ខខណ្ឌប្រើប្រាស់ទាំងនេះត្រូវបានធ្វើបច្ចុប្បន្នភាពនៅថ្ងៃទី១៦ ខែតុលា ឆ្នាំ២០២៣។</div>
                    </li>
                </ol>
            </li>
        </ol>
    </li>
</ol>
<h4>កាលវិភាគ</h4>
<ol>
    <li>
        <strong>ឈ្មោះសេវាកម្ម៖ នាំសារ(Nomsa)</strong>
    </li>
    <li>
        <strong>ធម្មជាតិនៃសេវាកម្ម</strong>
        <ol className="order-letter">
            <li>
                ទោះបីជាមានលក្ខខណ្ឌក្នុងការប្រើប្រាស់ក៏ដោយ សេវានេះត្រូវបានបម្រុងទុកសម្រាប់ប្រើប្រាស់ដោយទី 
                ភ្នាក់ងារវិស័យសាធារណៈនៅកម្ពុជា។            
            </li>
            <li>
                សេវាកម្មនេះគឺជាឧបករណ៍ផ្ញើសារដ៏ធំសម្រាប់អង្គភាពដែលត្រូវបានអនុញ្ញាត (មានរាយក្នុងកថាខណ្ឌរងមួយខាងលើ) 
            </li>
            <li>
                អ្នកទទួលខុសត្រូវក្នុងការធានាថាការប្រើប្រាស់សេវាកម្មរបស់អ្នកគឺអនុលោមតាមច្បាប់ជាធរមានទាំងអស់ រួមទាំង 
                ដោយគ្មានដែនកំណត់ ច្បាប់ការពារទិន្នន័យផ្ទាល់ខ្លួន និងច្បាប់គ្រប់គ្រងសារឥតបានការ។            
            </li>
            <li>
                DGC មិនទទួលខុសត្រូវចំពោះខ្លឹមសារនៃសារដែលអ្នកជ្រើសរើសផ្ញើ ឬចំពោះខ្លឹមសារនៃកិច្ចព្រមព្រៀងណាមួយដែល 
                អ្នកមាន(ឬបញ្ជាក់ថាមាន)ជាមួយអ្នកទទួលសាររបស់អ្នក។           
            </li>
            <li>
                ការប្រើប្រាស់សេវាកម្មអាចតម្រូវឱ្យអ្នកមានសិទ្ធិប្រើប្រាស់អ្នកផ្តល់សេវាភាគីទីបីជាក់លាក់រួចហើយ។ ឧទាហរណ៍ អ្នក 
                ប្រហែលជាត្រូវមានគណនី Nomsa ដើម្បីប្រើប្រាស់សេវាកម្ម។ អ្នកអាចនឹងត្រូវបានតម្រូវឱ្យផ្តល់ព័ត៌មានលម្អិតនៃគណនី 
                របស់អ្នក ដើម្បីប្រើប្រាស់សេវាកម្ម។            
            </li>
            <li>
                អ្នកធានា និងតំណាងឱ្យ DGC ថា(ដោយគ្មានការរើសអើងចំពោះសិទ្ធិផ្សេងទៀតរបស់ DGC នៅក្នុងលក្ខខណ្ឌនៃការ 
                ប្រើប្រាស់ដូចជា ប្រការ៣.២ និង៦) អ្នកមានសិទ្ធិពេញលេញក្នុងការប្រើប្រាស់សេវាកម្មភាគីទីបីបែបនេះនៅក្នុង ឬជាមួយ 
                សេវាកម្ម និងសកម្មភាព និង/ឬការខកខានរបស់អ្នកនៅក្នុងការគោរព។ នៃសេវាកម្មបែបនេះនឹងមិនបណ្តាលឱ្យ DGCទទួល 
                ខុសត្រូវចំពោះភាគីទីបីណាមួយ រួមទាំងអ្នកផ្តល់សេវាផងដែរ។            
            </li>
        </ol>
    </li>
    <li>
        <strong>កម្មវិធី/សេវាកម្មភាគីទីបី</strong>
        <div>ក. សេវាកម្មគេហទំព័រ Amazon - លក្ខខណ្ឌសេវាកម្ម (<a href="https://aws.amazon.com/service-terms/" target="_blank">https://aws.amazon.com/service-terms/</a>)</div>
    </li>
</ol>
</body>
`
