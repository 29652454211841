import { i18n } from '@lingui/core'
import { en, km } from 'make-plural/plurals'

/**
 * To create a new language or translation, for example, `en-custom`:
 * Add it to the `locales` array in `.linguirc` and run `npm run extract`
 */

export const defaultLocale = 'km'
import defaultLocaleMsg from './km/messages.js'
i18n.loadLocaleData({
  en: { plurals: en },
  km: { plurals: km },
})
i18n.load(defaultLocale, defaultLocaleMsg.messages)
i18n.activate(defaultLocale) // language to display for the site. Currently this is hard-coded and cannot be changed via UI.

export async function dynamicActivate(locale: string) {
  const { messages } = await import(`./${locale}/messages`)
  i18n.load(locale, messages)
  i18n.activate(locale)
}

export async function getCurrentLocale() {
  const currentLanguage = window.localStorage.getItem('language')
  if (!currentLanguage) {
    window.localStorage.setItem('language', 'km')
    await dynamicActivate('km')
  } else {
    await dynamicActivate(currentLanguage)
  }
}
