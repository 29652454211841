import cx from 'classnames'
import { useContext, useEffect, useState } from 'react'

import styles from './Privacy.module.scss'

import { enContent } from './content/en'
import { kmContent } from './content/km'

import { NavBar as DashboardNavBar } from 'components/common'
import LandingFooter from 'components/landing/footer'
import Navbar from 'components/landing/nav-bar/Navbar'
import { AuthContext } from 'contexts/auth.context'
import ModalContextProvider from 'contexts/modal.context'

const PrivacyPolicy = ({ handleLocaleChange }: any) => {
  const { isAuthenticated } = useContext(AuthContext)
  const [html, setHtml] = useState('')
  const lang = window.localStorage.getItem('language')

  function fetchHtml(lang = 'km') {
    if (lang === 'en') setHtml(enContent)
    else setHtml(kmContent)
  }

  useEffect(() => {
    fetchHtml(lang || 'km')
  }, [lang])

  return (
    <ModalContextProvider>
      {isAuthenticated ? (
        <>
          <DashboardNavBar
            handleLocaleChange={handleLocaleChange}
          ></DashboardNavBar>
          <div className={cx(styles.container, styles.authenticate)}>
            <div className={styles.previewContainer}>
              <div dangerouslySetInnerHTML={{ __html: html }}></div>
            </div>
          </div>
        </>
      ) : (
        <div className={styles.container}>
          <Navbar handleLocaleChange={handleLocaleChange} />
          <div className={styles.previewContainer}>
            <div dangerouslySetInnerHTML={{ __html: html }}></div>
          </div>
        </div>
      )}
      <LandingFooter />
    </ModalContextProvider>
  )
}

export default PrivacyPolicy
