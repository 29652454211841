import { i18n } from '@lingui/core'
import { t } from '@lingui/macro'

import cx from 'classnames'

import { createRef, useContext, useEffect, useState } from 'react'

import { OutboundLink } from 'react-ga'
import { Navigate, useNavigate } from 'react-router-dom'

import styles from './Landing-v1.module.scss'

import Navbar from './nav-bar'

import cplogoImg from 'assets/img/landing/cp-logo.png'
import dgclogoImg from 'assets/img/landing/dgc-logo.png'
import iconDice from 'assets/img/landing/icon/dice-bg.svg'
import iconSms from 'assets/img/landing/icon/email-outline.svg'
import iconEmail from 'assets/img/landing/icon/outline-alternate-email.svg'
import iconTelegram from 'assets/img/landing/icon/telegram.svg'
import landingHeroImg from 'assets/img/landing/landing-hero-v1.png'
import mcslogoImg from 'assets/img/landing/mcs-logo.png'
import moclogoImg from 'assets/img/landing/moc-logo.png'
import userImg from 'assets/img/landing/moe-circle.png'
import mptclogoImg from 'assets/img/landing/mptc-logo.png'
import tclogoImg from 'assets/img/landing/tc-logo.png'
import trclogoImg from 'assets/img/landing/trc-logo.png'
import whyUse1 from 'assets/img/why-use-1-v1.png'
import whyUse2 from 'assets/img/why-use-2-v1.png'
import whyUse3 from 'assets/img/why-use-3-v1.png'
import { InfoBanner, PrimaryButton } from 'components/common'
import LandingFooter from 'components/landing/footer'
import { LINKS } from 'config'

import { AuthContext } from 'contexts/auth.context'
import { getLandingStats } from 'services/stats.service'

const Landing = ({ handleLocaleChange }: any) => {
  const { isAuthenticated } = useContext(AuthContext)
  const [sentMessages, setSentMessages] = useState('0')
  const navigate = useNavigate()

  const bannerRef = createRef<HTMLDivElement>()
  const infoBannerRef = createRef<HTMLDivElement>()

  useEffect(() => {
    if (isAuthenticated) return
    async function getSentMessages() {
      const stats = await getLandingStats()
      if (stats) {
        setSentMessages(stats.toLocaleString())
      }
    }
    void getSentMessages()
  }, [isAuthenticated])

  useEffect(() => {
    function recalculateBannerPos() {
      const govBannerHeight = bannerRef.current?.offsetHeight as number
      const scrollTop = (document.documentElement.scrollTop ||
        document.body.scrollTop) as number
      if (infoBannerRef.current) {
        const offsetTop =
          scrollTop >= govBannerHeight ? 0 : govBannerHeight - scrollTop
        const infoBannerHeight = infoBannerRef.current?.offsetHeight as number
        if (scrollTop > govBannerHeight + infoBannerHeight) {
          infoBannerRef.current.style.position = 'fixed'
          infoBannerRef.current.style.top = `${offsetTop}px`
        } else {
          infoBannerRef.current.style.position = 'relative'
        }
      }
    }
    window.addEventListener('scroll', recalculateBannerPos)
    return () => {
      window.removeEventListener('scroll', recalculateBannerPos)
    }
  })

  if (isAuthenticated) {
    return <Navigate to="/campaigns" />
  }

  function directToSignIn() {
    navigate('/login')
  }

  const trustedAgencies = [
    { img: mptclogoImg, alt: 'MPTC', link: 'https://mptc.gov.kh/' },
    { img: dgclogoImg, alt: 'DGC', link: 'https://go.gov.kh/dgc/site' },
    { img: trclogoImg, alt: 'TRC', link: 'https://trc.gov.kh/' },
    { img: tclogoImg, alt: 'TC', link: 'http://www.tc.com.kh/public/' },
    { img: cplogoImg, alt: 'CP', link: 'https://cambodiapost.com.kh/' },
    { img: moclogoImg, alt: 'MOC', link: 'https://www.moc.gov.kh/' },
    { img: mcslogoImg, alt: 'MCS', link: 'https://www.mcs.gov.kh/' },
  ]

  const reasons = [
    {
      img: whyUse1,
      firstHeader: t`why use 1 first header`,
      firstText: t`why use 1 first text`,
      secondHeader: t`why use 1 second header`,
      secondText: t`why use 1 second text`,
    },
    {
      img: whyUse2,
      firstHeader: t`why use 2 first header`,
      firstText: t`why use 2 first text`,
      secondHeader: t`why use 2 second header`,
      secondText: t`why use 2 second text`,
    },
    {
      img: whyUse3,
      firstHeader: t`why use 3 first header`,
      firstText: t`why use 3 first text`,
    },
  ]

  return (
    <div className={styles.landing}>
      {/* Hide banner */}
      {/* <Banner innerRef={bannerRef} /> */}
      <InfoBanner innerRef={infoBannerRef} />
      <div className={styles.topContainer}>
        <Navbar handleLocaleChange={handleLocaleChange} />
        <div className={styles.innerContainer}>
          <div className={styles.textContainer}>
            <h1 className={styles.headerText}>{t`reach out`}</h1>
            <div>
              <div
                className={cx(styles.sentMessages, {
                  [styles.ready]: sentMessages !== '0',
                })}
              >
                <span className={styles.numOfMessages}>{sentMessages}</span>
                <span className={styles.text}>{t`landing.messagesSent`}</span>
              </div>
              <div className={styles.signInRow}>
                <div className={styles.signInButtonContainer}>
                  <PrimaryButton
                    className={styles.signInButton}
                    onClick={directToSignIn}
                  >
                    {t`Sign In`}
                    <i className="bx bx-send"></i>
                  </PrimaryButton>
                </div>
                <OutboundLink
                  className={styles.contactUs}
                  eventLabel={i18n._(LINKS.contactUsUrl)}
                  to={i18n._(LINKS.contactUsUrl)}
                  target="_blank"
                >
                  {t`landing.needHelp`}
                  <span>{t`landing.talkToUs`}</span>
                </OutboundLink>
              </div>
            </div>
          </div>
          <div className={styles.landingAnimation}>
            <img src={landingHeroImg} className={styles.landingHero} alt="" />
          </div>
        </div>
        <div className={styles.agencyContainer}>
          <h2 className={styles.agencyHeader}>{t`landing.trustBy`}</h2>
          <div className={styles.agencies}>
            {trustedAgencies.map((agency) => (
              <OutboundLink
                eventLabel={agency.alt}
                to={agency.link}
                target="_blank"
                key={agency.img}
              >
                <img src={agency.img} alt={agency.alt} />
              </OutboundLink>
            ))}
          </div>
        </div>
      </div>

      <div className={styles.channelContainer}>
        <div className={styles.innerContainer}>
          <div className={styles.channelTitle}>
            <h4>{t`landing.channels`}</h4>
            <div className={styles.channelTitleUnderline} />
          </div>
          <div className={styles.channelDescription}>
            <div className={styles.channelCard}>
              <div className={styles.channelIconImg}>
                <img src={iconEmail} alt="email" />
              </div>
              <h4>{t`landing.email`}</h4>
              <p>
                {t`landing.channel.emailDesc`}{' '}
                <OutboundLink
                  eventLabel="https://guide.nomsa.gov.kh/campaign-guide-email/email-campaigns-basics"
                  to="https://guide.nomsa.gov.kh/campaign-guide-email/email-campaigns-basics"
                  target="_blank"
                >
                  {t`read more`}
                </OutboundLink>
              </p>
            </div>

            <div className={styles.channelCard}>
              <div className={styles.channelIconImg}>
                <img src={iconSms} alt="sms" />
              </div>
              <h4>{t`landing.sms`}</h4>
              <p>
                {t`landing.channel.smsDesc`}{' '}
                <OutboundLink
                  eventLabel="https://guide.nomsa.gov.kh/campaign-guide-sms/sms-campaigns-basics"
                  to="https://guide.nomsa.gov.kh/campaign-guide-sms/sms-campaigns-basics"
                  target="_blank"
                >
                  {t`read more`}
                </OutboundLink>
              </p>
            </div>

            <div className={styles.channelCard}>
              <div className={styles.channelIconImg}>
                <img src={iconTelegram} alt="telegram" />
              </div>
              <h4>{t`landing.telegram`}</h4>
              <p>
                {t`landing.channel.telegramDesc`}{' '}
                <OutboundLink
                  eventLabel="https://guide.nomsa.gov.kh/campaign-guide-telegram/telegram-campaigns-basics"
                  to="https://guide.nomsa.gov.kh/campaign-guide-telegram/telegram-campaigns-basics"
                  target="_blank"
                >
                  {t`read more`}
                </OutboundLink>
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className={styles.whyUsePostman}>
        <div className={styles.innerContainer}>
          <div className={styles.title}>
            <h4>{t`landing.whyPostman`}</h4>
            <div className={styles.titleUnderline} />
          </div>
          <div className={styles.reasons}>
            {reasons.map((reason) => (
              <div className={styles.reason} key={reason.img}>
                <img
                  src={reason.img}
                  alt={`${reason.firstHeader} ${reason.secondHeader}`}
                />
                <div className={styles.textContainer}>
                  <div className={styles.headerText}>
                    <img height="35" width="36" src={iconDice} alt="icon" />
                    {reason.firstHeader}
                  </div>
                  <p>{reason.firstText}</p>
                  {reason.secondHeader && (
                    <div className={styles.headerText}>
                      <img height="35" width="36" src={iconDice} alt="icon" />
                      {reason.secondHeader}
                    </div>
                  )}
                  {reason.secondText && <p>{reason.secondText}</p>}
                </div>
              </div>
            ))}
          </div>

          <div className={styles.lineBreak} />

          <div className={styles.testimonial}>
            <span className={cx(styles.openInvertedComma, styles.comma)}>
              &#8220;
            </span>
            <div className={styles.inner}>
              <img className={styles.desktopImg} src={userImg} alt="User" />
              <div className={styles.textContainer}>
                <p className={styles.longText}>
                  {t`landing.testimonial.longText`}
                </p>
                <div className={styles.agencyRow}>
                  <span>{t`landing.testimonial.useFor`}</span>
                </div>
              </div>
            </div>
            <span className={cx(styles.closeInvertedComma, styles.comma)}>
              &#8221;
            </span>
          </div>
        </div>
      </div>

      <div className={styles.onboarding}>
        <div className={styles.innerContainer}>
          <div className={styles.textContainer}>
            <div className={styles.textDescription}>
              <h2>{t`landing.onboarding.header`}</h2>
              <p>{t`landing.onboarding.text`}</p>
            </div>

            <div className={styles.buttonRow}>
              <PrimaryButton
                className={styles.getStartedButton}
                onClick={directToSignIn}
              >
                <span>{t`get started`}</span>
              </PrimaryButton>
              <OutboundLink
                eventLabel={i18n._(LINKS.contactUsUrl)}
                to={i18n._(LINKS.contactUsUrl)}
                target="_blank"
              >
                {t`contact us`}
              </OutboundLink>
            </div>
          </div>
        </div>
      </div>
      <LandingFooter />
    </div>
  )
}

export default Landing
