export const kmContent = `
<body>
<h1>គោលការណ៍ឯកជនភាព</h1>
<div>គោលការណ៍ឯកជនភាពនេះត្រូវតែអានដោយភ្ជាប់ជាមួយលក្ខខណ្ឌនៃការប្រើប្រាស់ដែលអមជាមួយសេវាកម្មដែលអាច 
អនុវត្តបានដែលអ្នកកំពុងស្នើសុំពីយើង("សេវាកម្ម")។ នៅក្នុងគោលការណ៍ឯកជនភាពនេះ “អង្គភាពវិស័យសាធារណៈ” 
មានន័យថា រដ្ឋាភិបាល(រួមទាំងក្រសួង នាយកដ្ឋាន និងស្ថាប័នរដ្ឋ) និងអាជ្ញាធរសាធារណៈ(ដូចជាក្រុមប្រឹក្សាច្បាប់)។</div>
<ol>
    <li>
        ដរាបណាសេវាកម្មមាន ឬត្រូវបានផ្តល់ជូនអ្នកតាមរយៈគេហទំព័រ សូមចំណាំថា៖
        <ol>
            <li>
                យើងអាចប្រើ “ខូគី(Cookies)” ដែលឯកសារទិន្នន័យតូចមួយត្រូវបានផ្ញើទៅកាន់កម្មវិធីរុករក(Browser)តាមអ៊ីនធឺណិតរបស់អ្នកដើម្បីរក្សាទុកនិងតាមដានព័ត៌មានអំពីអ្នកនៅពេលអ្នកចូលគេហទំព័ររបស់យើង។ ខូគី(Cookies)ត្រូវបាន ប្រើដើម្បីតាមដានព័ត៌មានដូចជាចំនួនអ្នកប្រើប្រាស់និងភាពញឹកញាប់នៃការប្រើប្រាស់របស់ពួកគេទម្រង់អ្នកប្រើប្រាស់និងគេហទំព័រដែលពួកគេចូលចិត្ត។ ខណៈដែលខូគី(Cookies)នេះអាចប្រាប់យើងនៅពេលអ្នកចូលគេហទំព័ររបស់យើង និងទំព័រណាដែលអ្នកចូលមើល វាមិនអាចអានទិន្នន័យចេញពីថាសរឹងរបស់អ្នកបានទេ។ 
            </li>
            <li>
                អ្នកអាចជ្រើសរើសទទួលយក ឬបដិសេធ Cookies។ Browser តាម Internet ភាគច្រើនទទួលយក  
                Cookies ដោយស្វ័យប្រវត្តិ ប៉ុន្តែជាធម្មតាអ្នកអាចកែប្រែការកំណត់ Browser របស់អ្នកដើម្បីបដិសេធ Cookies 
                ប្រសិនបើអ្នកចង់បាន។ នេះអាចរារាំងអ្នកពីការទាញយកអត្ថប្រយោជន៍ពេញលេញពីគេហទំព័រ។
            </li>
        </ol>
    </li>
    <li>
        យើងអាចស្នើសុំប្រភេទទិន្នន័យមួយចំនួនពីអ្នកទាក់ទងនឹងការចូលប្រើ ឬការប្រើប្រាស់សេវាកម្មរបស់អ្នក។  
        ទិន្នន័យដែលអាចត្រូវបានស្នើសុំរួមបញ្ចូលទាំងទិន្នន័យដែលបានកំណត់ក្នុងឧបសម្ព័ន្ធនៅទីនេះ។ ទិន្នន័យរបស់ 
        អ្នកអាចត្រូវបានរក្សាទុកនៅក្នុងម៉ាស៊ីនមេ ប្រព័ន្ធឬឧបករណ៍របស់យើងនៅក្នុងម៉ាស៊ីនមេប្រព័ន្ធឬឧបករណ៍ 
        របស់អ្នកផ្តល់សេវាភាគីទីបីឬអ្នកសហការរបស់យើងឬនៅលើឧបករណ៍របស់អ្នកហើយអាចត្រូវបានប្រើប្រាស់ដោយ 
        យើង ឬអ្នកផ្តល់សេវាភាគីទីបីរបស់យើងឬអ្នកសហការដើម្បីជួយសម្រួល។ ការចូលប្រើឬប្រើប្រាស់សេវាកម្ម 
        របស់អ្នក។  
    </li>
    <li>
        ប្រសិនបើអ្នកផ្តល់ឱ្យយើងនូវទិន្នន័យផ្ទាល់ខ្លួនដែលអាចកំណត់អត្តសញ្ញាណបាន៖
        <ol>
            <li>
                យើងអាចប្រើប្រាស់ បង្ហាញ និងដំណើរការទិន្នន័យសម្រាប់គោលបំណងណាមួយ ឬច្រើនដូចខាងក្រោម៖
                <ol>
                    <li>
                        ដើម្បីជួយ ដំណើរការ និងសម្រួលដល់ការចូលប្រើ ឬប្រើប្រាស់សេវាកម្មរបស់អ្នក។
                    </li>
                    <li>
                        ដើម្បីគ្រប់គ្រង ដំណើរការ និងសម្របសម្រួលប្រតិបត្តិការ ឬសកម្មភាពណាមួយដោយអ្នក មិនថាជា 
                        មួយយើង ឬអង្គភាពវិស័យសាធារណៈផ្សេងទៀត ឬអ្នកផ្តល់សេវាភាគីទីបីឬអ្នកសហការនិងថាតើសម្រាប់ផល 
                        ប្រយោជន៍ផ្ទាល់ខ្លួនរបស់អ្នក ឬសម្រាប់ផលប្រយោជន៍របស់ភាគីទីបីក្នុងនាមអ្នកអនុញ្ញាតត្រឹមត្រូវដើម្បីធ្វើ 
                        សកម្មភាព
                    </li>
                    <li>
                        ដើម្បីអនុវត្តការណែនាំរបស់អ្នក ឬឆ្លើយតបទៅនឹងសំណួរ មតិកែលម្អ ឬការត្អូញត្អែរដែលផ្តល់ដោយ 
                        (ឬត្រូវបានគេសន្មតថាត្រូវបានផ្តល់ដោយ) អ្នក ឬក្នុងនាមអ្នក ឬក្នុងគោលបំណងឆ្លើយតបឬដោះស្រាយអន្តរកម្ម 
                        របស់អ្នកជាមួយយើង។ 
                    </li>
                    <li>
                        ដើម្បីតាមដាន និងតាមដានការប្រើប្រាស់សេវាកម្មរបស់អ្នក ដើម្បីធ្វើការស្រាវជ្រាវ ការវិភាគទិន្នន័យ  
                        ការស្ទង់មតិ ការសិក្សាទីផ្សារ និងសកម្មភាពស្រដៀងគ្នា ដើម្បីជួយយើងក្នុងការយល់ដឹងអំពីចំណាប់អារម្មណ៍ 
                        កង្វល់ និងចំណូលចិត្តរបស់អ្នក និងធ្វើអោយប្រសើរឡើងនូវសេវាកម្ម និងសេវាកម្មនិងផលិតផលផ្សេងទៀត 
                        ដែលផ្តល់ដោយសាធារណៈ អង្គភាពវិស័យ។ សម្រាប់ការជៀសវាងការសង្ស័យ យើងក៏អាចប្រមូលប្រើប្រាស់ 
                        បង្ហាញ និងដំណើរការព័ត៌មានទាំងនោះ ដើម្បីបង្កើតរបាយការណ៍ និងផលិតស្ថិតិទាក់ទងនឹងប្រតិបត្តិការរបស់ 
                        អ្នកជាមួយយើង និងការប្រើប្រាស់សេវាកម្មរបស់អ្នក និងសេវាកម្ម និងផលិតផលផ្សេងទៀតដែលផ្តល់ដោយ 
                        អង្គភាពវិស័យសាធារណៈសម្រាប់ការរក្សាទុក និងគោលបំណងនៃការរាយការណ៍ ឬការបោះពុម្ពផ្សាយ(មិនថា 
                        ខាងក្នុង ឬខាងក្រៅ)
                    </li>
                    <li>
                        សម្រាប់គោលបំណងរក្សាទុក ឬបង្កើតការបម្រុងទុកទិន្នន័យរបស់អ្នក (មិនថាសម្រាប់គោលបំណង 
                        បន្ត ឬបន្តអាជីវកម្ម ឬផ្សេងទៀត)មិនថាក្នុង ឬក្រៅកម្ពុជា។ 
                    </li>
                    <li>
                        ដើម្បីឱ្យពួកយើងអាចទាក់ទងអ្នក ឬទំនាក់ទំនងជាមួយអ្នកលើបញ្ហាណាមួយដែលទាក់ទងនឹងការចូល 
                        ប្រើ ឬការប្រើប្រាស់សេវាកម្មរបស់អ្នក រួមទាំងប៉ុន្តែមិនកំណត់ចំពោះគោលបំណងដែលបានកំណត់ខាងលើតាម 
                        រយៈអ៊ីមែល ការជូនដំណឹងជំរុញ ឬទម្រង់ទំនាក់ទំនងផ្សេងទៀតដែលយើងអាចណែនាំពីម្តងម្កាលអាស្រ័យលើ 
                        មុខងារនៃសេវាកម្ម និង/ឬឧបករណ៍របស់អ្នក។
                    </li>
                </ol>
            </li>
            <li>
                យើងអាចចែករំលែកទិន្នន័យចាំបាច់ជាមួយអង្គភាពវិស័យសាធារណៈផ្សេងទៀត និងអ្នកផ្តល់សេវាភាគីទី 
                បីទាក់ទងនឹងសេវាកម្ម ដើម្បីផ្តល់សេវាកម្មដល់អ្នកប្រកបដោយប្រសិទ្ធភាព និងប្រសិទ្ធភាពបំផុតលុះត្រាតែការ 
                ចែករំលែកបែបនេះត្រូវបានហាមឃាត់ដោយច្បាប់។ 
            </li>
            <li>
                យើងនឹងមិនចែករំលែកទិន្នន័យផ្ទាល់ខ្លួនរបស់អ្នកជាមួយអង្គភាពដែលមិនមែនជាអង្គភាពវិស័យ 
                សាធារណៈទេ លើកលែងតែកន្លែងដែលការចែករំលែកបែបនេះចាំបាច់សម្រាប់អង្គភាពទាំងនោះដើម្បីជួយយើង 
                ក្នុងការផ្តល់សេវាកម្មដល់អ្នកឬសម្រាប់ការបំពេញគោលបំណងណាមួយនៅក្នុងប្រការ៣នេះ។ 
            </li>
            <li>
                សម្រាប់ភាពងាយស្រួលអ្នក យើងអាចបង្ហាញអ្នកនូវប្រវត្តិទិន្នន័យដែលអ្នកបានផ្តល់ឱ្យយើង ឬអង្គភាព 
                វិស័យសាធារណៈផ្សេងទៀត។ វានឹងបង្កើនល្បឿនប្រតិបត្តិការ និងជួយសង្រ្គោះអ្នកពីបញ្ហានៃការធ្វើម្តងទៀត 
                នូវការដាក់ស្នើពីមុន។ ប្រសិនបើទិន្នន័យហួសសម័យ សូមផ្តល់ទិន្នន័យចុងក្រោយបំផុតដល់ពួកយើង។
            </li>
        </ol>
    </li>
    <li>
        ដើម្បីការពារទិន្នន័យផ្ទាល់ខ្លួនរបស់អ្នក ការផ្ទុកអេឡិចត្រូនិច និងការបញ្ជូនទិន្នន័យផ្ទាល់ខ្លួនទាំងអស់ត្រូវ 
        បានធានាដោយបច្ចេកវិទ្យាសុវត្ថិភាពសមស្រប។ 
    </li>
    <li>
        អ្នកអាចដកការយល់ព្រមរបស់អ្នកចំពោះការប្រើប្រាស់ និងការបង្ហាញទិន្នន័យរបស់អ្នកដោយពួកយើង 
        ជាមួយនឹងការជូនដំណឹងសមហេតុផល និងជាកម្មវត្ថុនៃការរឹតបន្តឹងផ្នែកច្បាប់ ឬកិច្ចសន្យា។ ទោះយ៉ាងណាក៏ 
        ដោយ ការធ្វើដូច្នេះអាចរារាំងដំណើរការត្រឹមត្រូវនៃសេវាកម្ម ហើយក៏អាចនាំឱ្យអ្នកបញ្ឈប់សេវាកម្មផងដែរ។
    </li>
    <li>
        សេវាកម្មអាចមានតំណភ្ជាប់ទៅកាន់គេហទំព័រខាងក្រៅដែលការការពារទិន្នន័យ និងការអនុវត្តឯកជនភាព 
        អាចខុសពីគេហទំព័ររបស់យើង។ យើងមិនទទួលខុសត្រូវចំពោះខ្លឹមសារ និងការអនុវត្តឯកជនភាពនៃគេហទំព័រ 
        ផ្សេងទៀតទាំងនេះ ហើយលើកទឹកចិត្តឱ្យអ្នកពិគ្រោះជាមួយការជូនដំណឹងអំពីឯកជនភាពនៃគេហទំព័រទាំងនោះ។ 
    </li>
    <li>
        សូមទំនាក់ទំនទង <a href="info@dgc.gov.kh">info@dgc.gov.kh</a> ប្រសិនបើអ្នក៖ 
        <ol>
            <li>
                មានការសាកសួរ ឬមតិកែលម្អលើគោលការណ៍ និងនីតិវិធីការពារទិន្នន័យរបស់យើង ឬ
            </li>
            <li>
                ត្រូវការព័ត៌មានបន្ថែមអំពី ឬចូលប្រើទិន្នន័យដែលអ្នកបានផ្តល់មកយើងនាពេលកន្លងមក។
            </li>
        </ol>
    </li>
</ol>
<div>កំណែនៃគោលការណ៍ឯកជនភាពនេះគឺចុះថ្ងៃទី១៦ ខែតុលា ឆ្នាំ២០២៣។ </div>
<h2><strong>ឧបសម្ព័ន្ធ</strong></h2>
<div><strong>ឈ្មោះសេវាកម្ម </strong>៖ នាំសារ(Nomsa)</div>
<div>ប្រភេទនៃទិន្នន័យដែលបានប្រមូល / ស្នើសុំ </div>
<div>ក. អ៊ីមែលអ្នកប្រើប្រាស់</div>
<div>ខ. គំរូសារ</div>
<div>ឧបសម្ព័ន្ធនេះត្រូវបានធ្វើបច្ចុប្បន្នភាពចុងក្រោយនៅថ្ងៃទី១៦ ខែតុលា ឆ្នាំ២០២៣។ </div>
</body>
`
