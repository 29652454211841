import { FunctionComponent } from 'react'
import { OutboundLink } from 'react-ga'

import styles from './Footer.module.scss'

interface ImageProps {
  appLogo: any
}
interface LabelProps {
  appTitle?: string
  contactUs?: string
  appCredit?: string
}
interface ContactProps {
  email?: string
}
interface AddressProps {
  emailIcon?: any
  mapIcon?: any
  location?: string
  code?: string
}
interface BuildByItemProps {
  name?: string
  link?: string
  logo?: any
}
interface BuildByProps {
  title: string
  items: BuildByItemProps[]
}
interface FooterLinkProps {
  name?: string
  link?: string
  label?: string
}
interface SocialLinkProps {
  name?: string
  link?: string
  icon?: any
}
interface BaseFooterProps {
  images: ImageProps
  labels: LabelProps
  contacts: ContactProps
  address: AddressProps
  buildBy: BuildByProps
  footerLinks: FooterLinkProps[]
  socialLinks: SocialLinkProps[]
}
const BaseFooter: FunctionComponent<BaseFooterProps> = ({
  images,
  labels,
  contacts,
  address,
  buildBy,
  footerLinks,
  socialLinks,
}) => {
  const { appLogo } = images
  const { appTitle, contactUs, appCredit } = labels
  const { email } = contacts
  const { location, code, mapIcon, emailIcon } = address
  const { title: buildByLabel, items: buildByItems } = buildBy

  return (
    <footer className={styles.bottomContainer}>
      <div className={styles.linksContainer}>
        <div className={styles.navLinks}>
          <div className={styles.header}>
            <img src={appLogo} alt="App logo" />
            <span className={styles.text}>{appTitle || ''}</span>
          </div>
        </div>
        <span className={styles.verticalLine}></span>
        <div className={styles.bottomContact}>
          <span>{contactUs || ''}</span>
          <div className={styles.contactInfo}>
            <div className={styles.contactItem}>
              <div className={styles.icon}>{emailIcon}</div>
              <a href={`mailto:${email}`}>{email}</a>
            </div>
            <div className={styles.contactItem}>
              <div className={styles.icon}>{mapIcon}</div>
              <div className={styles.address}>
                <span>{location || ''}</span>
                <span>{code || ''}</span>
              </div>
            </div>
          </div>
        </div>
        <span className={styles.verticalLine}></span>
        <div className={styles.builtBy}>
          <div className={styles.builtByContainer}>
            <span>{buildByLabel || ''}</span>
            <div className={styles.builtByItems}>
              {buildByItems.map((item: any, index: number) => {
                return (
                  <OutboundLink
                    key={`buildBy-item-${index}`}
                    eventLabel={`${item.name}-logo`}
                    to={item.link}
                    target="_blank"
                  >
                    <img width={100} src={item.logo} alt="logo" />
                  </OutboundLink>
                )
              })}
            </div>
          </div>
        </div>
        <div className={styles.mobileFooterLinks}>
          <div className={styles.links}>
            {footerLinks.map((ft, index) => {
              return (
                <OutboundLink
                  className={styles.navLink}
                  key={`footer-link-${index}`}
                  eventLabel={ft.name || ''}
                  to={ft.link || ''}
                  target="_blank"
                >
                  {ft.label || ''}
                </OutboundLink>
              )
            })}
          </div>
        </div>
      </div>
      <div className={styles.footerContainer}>
        <div className={styles.footer}>
          <div className={styles.links}>
            {footerLinks.map((ft, index) => {
              return (
                <OutboundLink
                  className={styles.navLink}
                  key={`footer-link-${index}`}
                  eventLabel={ft.name || ''}
                  to={ft.link || ''}
                  target="_blank"
                >
                  {ft.label || ''}
                </OutboundLink>
              )
            })}
          </div>
          <span className={styles.copyright}>
            &copy; {new Date().getFullYear()} {appCredit || ''}
          </span>
          <div className={styles.socialLinks}>
            {socialLinks.map((sl, index) => {
              return (
                <OutboundLink
                  key={`social-link-${index}`}
                  eventLabel={sl.name || ''}
                  to={sl.link || ''}
                  target="_blank"
                >
                  {sl.icon}
                </OutboundLink>
              )
            })}
          </div>
        </div>
      </div>
    </footer>
  )
}

export default BaseFooter
