import { ReactNode } from 'react'

import styles from './StepHeader.module.scss'

const StepHeader = ({
  title,
  subtitle,
  children,
}: {
  title: string
  subtitle?: string
  children?: ReactNode
}) => {
  return (
    <div className={styles.stepHeader}>
      {subtitle && (
        <div className={styles.subTitleContainer}>
          <div className={styles.subTitleLine}></div>
          <h4>{subtitle}</h4>
        </div>
      )}
      <h3>{title}</h3>
      {children && <div className={styles.description}>{children}</div>}
    </div>
  )
}

export default StepHeader
