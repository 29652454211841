import { i18n } from '@lingui/core'
import { t } from '@lingui/macro'
import { OutboundLink } from 'react-ga'
import { useNavigate } from 'react-router-dom'

import styles from './NavBar-v1.module.scss'

import AppBrandmark from 'assets/img/brand/app-brandmark.svg'
import AppLogo from 'assets/img/brand/app-logo.svg'
import enFlagIcon from 'assets/img/flag/english.svg'
import kmFlagIcon from 'assets/img/flag/khmer.svg'
import PrimaryButton from 'components/common/primary-button'
import { LINKS } from 'config'

const NavBar = ({ handleLocaleChange }: any) => {
  const navigate = useNavigate()
  const currentLocale = window.localStorage.getItem('language')

  function directToSignIn() {
    navigate('/login')
  }

  return (
    <nav className={styles.navBar}>
      <a className={styles.appLogo} href="/">
        <img src={AppLogo} alt="Nomsa logo" className={styles.desktop} />
        <img
          src={AppBrandmark}
          alt="Nomsa brandmark"
          className={styles.mobile}
        />
      </a>
      <div className={styles.navbarLinks}>
        <div className={styles.links}>
          {/* <OutboundLink
            className={styles.link}
            eventLabel={i18n._(LINKS.contributeUrl)}
            to={i18n._(LINKS.contributeUrl)}
            target="_blank"
          >
            Contribute
          </OutboundLink> */}
          <PrimaryButton
            className={styles.langButton}
            onClick={() => {
              currentLocale === 'km'
                ? handleLocaleChange('en')
                : handleLocaleChange('km')
            }}
          >
            <img
              src={currentLocale === 'km' ? enFlagIcon : kmFlagIcon}
              alt="flagIcon"
            />
          </PrimaryButton>
          <OutboundLink
            className={styles.link}
            eventLabel={`Landing navbar / ${i18n._(LINKS.guideUrl)}`}
            to={i18n._(LINKS.guideUrl)}
            target="_blank"
          >
            {t`Guides`}
          </OutboundLink>
        </div>
        <PrimaryButton className={styles.signInButton} onClick={directToSignIn}>
          {t`Sign In`}
        </PrimaryButton>
      </div>
    </nav>
  )
}

export default NavBar
