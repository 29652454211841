import { t } from '@lingui/macro'

import appLogoGrey from 'assets/img/brand/app-logo-grey.svg'
import emailIcon from 'assets/img/icon/email.svg'
import locationIcon from 'assets/img/icon/location.svg'
import dgclogoImg from 'assets/img/landing/dgc-logo.png'
import mptclogoImg from 'assets/img/landing/mptc-logo.png'
import facebookImg from 'assets/social-media/facebook.svg'
import linkedInImg from 'assets/social-media/linkedin.svg'
import telegramImg from 'assets/social-media/telegram.svg'
import twitterImg from 'assets/social-media/twitter.svg'
import youtubeImg from 'assets/social-media/youtube.svg'
import BaseFooter from 'components/common/footer'

const LandingFooter = () => {
  const images = {
    appLogo: appLogoGrey,
  }
  const labels = {
    appTitle: t`footer.nomsaReach`,
    contactUs: t`contact us`,
    appCredit: t`Digital Government Committee`,
  }
  const contacts = {
    email: 'support@dgc.gov.kh',
  }
  const address = {
    location: `${t`footer.addressBuilding`} ${t`footer.addresLocate`} ${t`footer.addressCode`}`,
    code: '',
    emailIcon: <img src={emailIcon} alt="email" width="16" />,
    mapIcon: <img src={locationIcon} alt="location" width="16" />,
  }
  const buildBy = {
    title: t`Built by`,
    items: [
      {
        name: 'mptc-logo',
        link: 'https://mptc.gov.kh/',
        logo: mptclogoImg,
      },
      {
        name: 'dgc-logo',
        link: 'https://go.gov.kh/dgc/site',
        logo: dgclogoImg,
      },
    ],
  }
  const footerLinks = [
    {
      name: 'term-of-use',
      link: '/term-of-use',
      label: t`footer.termOfUse`,
    },
    {
      name: 'privacy',
      link: '/privacy',
      label: t`footer.Privacy`,
    },
  ]
  const socialLinks = [
    {
      name: 'Facebook',
      link: 'https://go.gov.kh/dgc/fb',
      icon: <img src={facebookImg} alt="facebook" width="16" />,
    },
    {
      name: 'Telegram',
      link: 'https://go.gov.kh/dgc/tg',
      icon: <img src={telegramImg} alt="telegram" width="16" />,
    },
    {
      name: 'Linkedin',
      link: 'https://go.gov.kh/dgc/lnkd',
      icon: <img src={linkedInImg} alt="linkedIn" width="16" />,
    },
    {
      name: 'Youtube',
      link: 'https://www.youtube.com/@MPTCMedia',
      icon: <img src={youtubeImg} alt="youtube" width="16" />,
    },
    {
      name: 'Twitter',
      link: 'https://twitter.com/mptcgovkh',
      icon: <img src={twitterImg} alt="twitter" width="16" />,
    },
    // {
    //   name: 'Instagram',
    //   link: 'https://www.instagram.com/mptc.gov.kh',
    //   icon: <i className="bx bxl-instagram"></i>,
    // },
  ]
  return (
    <BaseFooter
      images={images}
      labels={labels}
      contacts={contacts}
      address={address}
      buildBy={buildBy}
      footerLinks={footerLinks}
      socialLinks={socialLinks}
    />
  )
}

export default LandingFooter
